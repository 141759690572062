import { rem } from 'polished';
import styled from 'styled-components';

type FeatureCardWrapperProps = {
  pill?: string;
};

export const FeatureCardImagesAndPriceWrapper = styled.div`
  position: absolute;
  z-index: 4;
`;

export const FeatureCardProductImageWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: ${rem('40px')};
    margin-right: ${rem('20px')};
  }
`;

export const FeatureCardWrapper = styled.div<FeatureCardWrapperProps>`
  &.propositionCard {
    background-color: var (--mineShaft);
    [class*='Interactionstyle__BlankInteraction'] {
      [class*='Containerstyle__Container'] {
        height: 100%;
        [class*='Containerstyle__ContainerContent'] {
          [class*='LinkCardContent'] {
            [class*='CardHeaderstyle__CardHeader'] {
              height: 192px;
              [class*='ImageWrapper'] {
                height: 100%;
                width: 100%;
                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            [class*='CardBodystyle__CardBody'] {
              padding: 0;
              [class*='CardBodystyle__ContentWrapper'] {
                [class*='CardBodystyle__ContentWrapperHeader'] {
                  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                    margin: 1rem 1rem -0.625rem;
                  }
                  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                    margin: 1.5rem 1.5rem 0;
                  }
                  h2 {
                    text-decoration: none;
                    font-weight: 700;
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      font-size: 1.625rem;
                      line-height: 1.813rem;
                    }
                    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                      font-size: 1.25rem;
                      line-height: 1.438rem;
                    }
                  }
                  [class*='Iconstyle__Icon-sc'] svg {
                    display: none;
                  }
                }
                [class*='Paragraphstyle__Paragraph'] {
                  p {
                    margin: 0;
                  }

                  ul {
                    padding-right: 1rem;

                    li {
                      p {
                        padding: 1.5rem 0 0;
                        line-height: 1.1rem;
                      }
                    }

                    + p {
                      display: none;
                    }
                    padding-left: 2rem;
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      padding-left: 2.4rem;
                    }
                  }
                  p {
                    margin: 0;
                    font-size: ${rem('18px')};
                    line-height: ${rem('18px')};
                    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                      padding: 1rem 0.938rem 2.063rem;
                    }
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                      padding: 1.5rem 1.5rem 2.875rem;
                    }
                    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                      line-height: 1.5rem;
                      padding: 1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  background: var(--white);
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 30%);
  max-width: 100%;
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-inline: ${rem('10px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    margin-inline: ${rem('12px')};
  }

  ${FeatureCardImagesAndPriceWrapper},
  [class*='LinkCardContent'] [class*='CardHeader'] [class*='ImageWrapper'] {
    height: ${rem('282px')};
    flex-basis: 100%;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='LinkCardstyle__LinkCard'] {
    width: auto;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'] {
    box-shadow: unset;
  }

  [class*='Interactionstyle__BlankInteraction'][class*='LinkCardstyle__LinkCard'],
  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'],
  [class*='Containerstyle__ContainerContent'] {
    height: auto;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      height: 87%;
    }
  }

  [class*='LinkCardContent'] {
    [class*='CardHeader'] {
      [class*='PillWrapper'] {
        display: flex;
        position: absolute;
        top: ${rem('351px')};
        left: ${rem('33px')};

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          left: ${rem('22px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
          left: ${rem('35px')};
        }

        > span {
          background: none;
          border: 0.063rem var(--mineShaft) solid;
          border-radius: ${rem('19.5px')};
          color: var(--mineShaft);
          font-size: ${rem('14px')};
          font-weight: 400;
          letter-spacing: ${rem('0.9px')};
          padding: ${rem('10px')} ${rem('16px')};
        }
      }
    }

    [class*='CardBodystyle__CardBody'] {
      cursor: default;
      padding-block: ${rem('19px')};
      padding-inline: ${rem('33px')} ${rem('24px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        padding-block: ${rem('18px')};
        padding-inline: ${rem('23px')} ${rem('20px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        padding-block: ${rem('20px')};
        padding-inline: ${rem('34px')} ${rem('20px')};
      }

      [class*='CardBodystyle__ContentWrapperHeader'] {
        align-items: center;
        justify-content: flex-end;
        margin-bottom: ${({ pill }) => (pill ? rem('70px') : rem('22px'))};

        [class*='Icon'] {
          margin-left: unset;

          svg {
            width: ${rem('20px')};
            height: ${rem('20px')};
          }
        }

        h2[size='2'] {
          font-weight: 200;
          color: var(--mineShaft);
          font-size: ${rem('28px')};
          letter-spacing: -${rem('0.3px')};
          line-height: ${rem('33px')};
          text-decoration: none;
        }
      }

      p {
        color: var(--mineShaft);
        font-size: ${rem('18px')};
        line-height: ${rem('24px')};

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          font-size: ${rem('18px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
          font-size: ${rem('18px')};
        }
      }
    }
  }

  [class*='Interactionstyle__Interaction'][class*='Buttonstyle__Button'] {
    font-size: ${rem('20px')};
    margin-block: ${rem('2px')} ${rem('37px')};
    margin-inline: ${rem('33px')};
    height: 100%;
    max-height: ${rem('50px')};
    width: ${rem('200px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      padding-block: ${rem('19px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-block: ${rem('3px')} ${rem('26px')};
      margin-inline: ${rem('22px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
      margin-block: ${rem('9px')} ${rem('43px')};
      margin-inline: ${rem('34px')} ${rem('20px')};
    }
  }
`;

export const ShortenedHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`;

export const ShortenedHeaderText = styled.div`
  font-weight: 200;
  font-size: 1.75rem !important;
  letter-spacing: -0.01875rem !important;
  line-height: 2.0625rem !important;
  margin: 0 !important;
  text-decoration: none !important;
`;
