import { InteractionEvent } from '@vfuk/core-interaction/dist/Interaction.types';
import TopNavigation from '@vfuk/core-top-navigation';
import { Link } from '@vfuk/core-top-navigation/dist/TopNavigation.types';
import { withPrefix } from 'gatsby';
import { useState, useEffect } from 'react';

import { useModalContext } from '@/hooks/modal/useModal';
import { useSWTopNavigation } from '@/hooks/useSWTopNavigation';
import { clearUserSession, useAppDispatch, useAppSelector } from '@/store';

import { TopNavigationWrapper } from './SWTopNavigation.styles';

const SWTopNavigation = () => {
  const { id, topNav, primaryNav, logoNav, secondaryNav } = useSWTopNavigation();
  const dispatch = useAppDispatch();
  const userBasket = useAppSelector(state => state.userBasket.data);
  const { user } = useAppSelector(state => state.userSession);
  const { toggleModal } = useModalContext();
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    if (user) renderUserName();
  }, []);

  useEffect(() => {
    if (user && showButton) renderSignOutButton();
    if (user && !showButton) deleteSignOutButton();
  }, [showButton]);

  const handleSignOut = (event: InteractionEvent) => {
    event.preventDefault();
    dispatch(clearUserSession());
    setShowButton(false);
  };

  const launchLogin = () => {
    toggleModal(true, 'Login');
  };

  const showSignOut = () => {
    setShowButton(true);
    setTimeout(() => setShowButton(false), 5000);
  };

  const renderUserName = () => {
    const node = document.createElement('p');
    const textnode = document.createTextNode(user?.selectedAccount?.customerAccountName || '');
    node.appendChild(textnode);
    document.querySelectorAll('[aria-label="Primary Navigation"]')[0]?.lastChild?.lastChild?.appendChild(node);
  };

  const renderSignOutButton = () => {
    const node = document.createElement('button');
    node.setAttribute('data-selector', 'Sign Out Button');
    node.setAttribute('class', ' button--sign-out');
    node.innerHTML = 'Sign Out';
    node.onclick = e => {
      handleSignOut(e);
    };
    document.querySelectorAll('[aria-label="Primary Navigation"]')[0]?.lastChild?.lastChild?.appendChild(node);
  };

  const deleteSignOutButton = () => {
    const primaryNav = document.querySelectorAll('[aria-label="Primary Navigation"]')[0];
    const lastChild = primaryNav?.lastChild?.lastChild as Element;
    const button = lastChild?.querySelector('[data-selector="Sign Out Button"]');
    if (button && !showButton) button?.remove();
  };

  const mapPrimaryLinks = (links: Link[]) => {
    return links.map(({ url, ...link }) => {
      const newLink = { ...link } as Link;

      if (link.links) {
        newLink.links = mapPrimaryLinks(link.links);
      } else if (url) {
        newLink.url = withPrefix(url);
      }

      return newLink;
    });
  };

  const mapSecondaryLinks = (links: Link[]) => {
    return links.map(({ url, ...link }) => {
      const newLink = { ...link } as Link;

      switch (link.text) {
        case 'Personal':
          newLink.url = 'https://n.vodafone.ie/en.html';
          break;
        case 'Business':
          newLink.url = 'https://n.vodafone.ie/business.html';
          break;
        default:
          break;
      }

      return newLink;
    });
  };

  return (
    <TopNavigationWrapper user={user?.selectedAccount?.customerAccountName}>
      {primaryNav && (
        <>
          <TopNavigation
            {...(topNav && {
              topNav: {
                ...topNav,
                leftLinks: mapSecondaryLinks(topNav.leftLinks || []),
              },
            })}
            primaryNav={{
              ...primaryNav,
              links: mapPrimaryLinks(primaryNav.links)
                ? [
                    {
                      id: 'home',
                      text: 'Marketplace: Home',
                      url: withPrefix('/'),
                    },
                    {
                      id: 'mobile',
                      text: ' Mobile',
                      links: [
                        {
                          id: 'phones',
                          text: 'Phones',
                          url: 'https://n.vodafone.ie/business/products-and-solutions/mobile-communications/ebu-phones.html',
                        },
                        {
                          id: 'plans',
                          text: 'Plans',
                          url: 'https://n.vodafone.ie/business/products-and-solutions/mobile-communications/ebu-mobile-plans.html',
                        },
                        {
                          id: 'upgrades',
                          text: 'Upgrades',
                          url: 'https://n.vodafone.ie.mcas.ms/business.html#login',
                        },
                        {
                          id: 'one-number',
                          text: 'One Number',
                          url: 'https://n.vodafone.ie/business/products-and-solutions/mobile-communications/one-number.html',
                        },
                      ],
                    },
                    {
                      id: 'broadband',
                      text: 'Broadband',
                      links: [
                        {
                          id: 'broadband',
                          text: 'Broadband',
                          url: 'https://n.vodafone.ie/business/products-and-solutions/fixed-communications/single-line.html',
                        },
                        {
                          id: 'mobile-broadband',
                          text: 'Mobile Broadband',
                          url: 'https://n.vodafone.ie/business/products-and-solutions/fixed-communications/mobile-broadband.html',
                        },
                      ],
                    },
                    {
                      id: 'business-boost',
                      text: 'Business Boost',
                      url: withPrefix('/marketplace/business-boost'),
                    },
                    {
                      id: 'office-anywhere',
                      text: 'Office Anywhere',
                      url: withPrefix('/marketplace/office-anywhere'),
                    },
                    {
                      id: 'get-in-touch',
                      text: 'Get in Touch',
                      url: 'https://www.vodafone.co.uk/business/contact-us',
                    },
                  ]
                : [],
            }}
            logo={{
              ...logoNav,
              url: 'https://n.vodafone.ie/business.html',
            }}
            {...(secondaryNav && {
              secondaryNav: {
                ...secondaryNav,
                iconNavLinks: user
                  ? [
                      {
                        id: 'your-basket',
                        text: 'Your Basket',
                        url: withPrefix('/marketplace/product-list/basket'),
                        icon: 'shopping-trolley',
                        numberBadge: userBasket.items.length || 0,
                      },
                      {
                        id: 'signed-in',
                        text: 'Signed In',
                        url: '',
                        icon: 'signed-in',
                        ...{ onClick: () => showSignOut() },
                      },
                    ]
                  : [
                      {
                        id: 'sign-out',
                        text: 'Sign Out',
                        url: '',
                        icon: 'admin',
                        ...{ onClick: () => launchLogin() },
                      },
                    ],
              },
            })}
            mobileNavTrigger={{
              id: `${id}-mobile-nav-trigger`,
              text: '',
            }}
            sideNavigationCloseButton={{
              id: `${id}-close-button`,
              srText: 'Close side navigation',
            }}
            skipLink={{
              text: `${id}-Skip to main content`,
              skipLinkId: '#main',
            }}
          />
        </>
      )}
    </TopNavigationWrapper>
  );
};

export default SWTopNavigation;
