import SearchInput from '@vfuk/core-search-input/dist/';
import { FC, useState } from 'react';

import { SWSearchBarInputProps } from './SWSectionSearchBar.types';

const SWSectionSearchBar: FC<SWSearchBarInputProps> = ({
  id,
  placeholder,
  label,
  showLabel,
  width,
}: SWSearchBarInputProps) => {
  const [searchText, setSearchText] = useState<string>('');
  return (
    <SearchInput
      textInput={{
        id,
        placeholder,
        value: searchText,
        onChange: e => setSearchText(e.target.value),
      }}
      fieldWrapper={{
        label,
        showLabel,
        width,
      }}
      onClear={() => setSearchText('')}
    />
  );
};

export default SWSectionSearchBar;
