import { graphql, useStaticQuery } from 'gatsby';

// TODO: Check if we will need this hook for vhub-plus, else just delete this file
const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            pathPrefix
            siteUrl
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
