import CardBuilder from '@vfuk/core-card-builder';
import CardHeadingAndIconSlot from '@vfuk/core-card-heading-and-icon-slot';
import CardIconSnippetSlot from '@vfuk/core-card-icon-snippet-slot';
import CardImageSlot from '@vfuk/core-card-image-slot';
import Icon from '@vfuk/core-icon';
import { getImage, getSrc } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { Article } from '../../../../types/Article.types';
import { Wrapper } from './SWArticleCard.styles';

const SWArticleCard: FC<Article> = ({ id, categories, name, thumbnail, summary, slug }: Article) => {
  const imageSlotSrc = getSrc(thumbnail) || getImage(thumbnail)?.placeholder?.fallback || '';

  const imageSlot = {
    alt: thumbnail?.title,
    backgroundImage: {
      sm: {
        src: imageSlotSrc,
      },
    },
  };

  return (
    <Wrapper>
      <CardBuilder key={id}>
        <CardHeadingAndIconSlot
          icon={{
            name: '',
            component: <CardImageSlot {...imageSlot} />,
          }}
          heading={{ name: categories ? categories[0].name : '' }}
          link={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Component CardHeadingAndIconSlot don't accept receiving a ReactNode at the text prop
            text: (
              <>
                {name}
                <Icon name="chevron-right" group="system" size={2} appearance="secondary" />
              </>
            ),
            href: `${slug}` || '#',
          }}
        />
        <CardIconSnippetSlot headingText="" icon={{ name: '' }} text={summary} />
      </CardBuilder>
    </Wrapper>
  );
};

export default SWArticleCard;
