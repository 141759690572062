import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const BasketSummaryWrapper = styled(Box)`

  button {
    display: none;
  }
  [class*='Interactionstyle__Interaction-sc'] {
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      padding: ${rem('26px')} ${rem('24px')};
    }
  }
  [data-component-name='Span'] {
    div {
      flex-direction: row; 
    }
  }
  [class*='BasketSummarystyle__BasketSummary-sc'] {
    z-index: 5;
    width: 100%;
    bottom: 0;
    [class*='BasketSummarystyle__BasketSummaryContainer'] {
      [class*='BasketSummarystyle__BasketSummaryHeading'] {
        [class*='Headingstyle__Heading'],
        [class*='Interactionstyle__Button'] {
          color: var(--mineShaft);
        }
        [class*='Interactionstyle__Button'] {
          display: none;
        }
      }
      [class*='BasketSummarystyle__BasketSummaryHeading-sc'] {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          margin-right: 0;
          padding-right: 0;
        }
      }
      [class*='BasketSummarystyle__Sandwiches-sc'] {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          margin: auto;
          padding: 0 ${rem('5px')};
        }
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg + 155}px) {
        width: 73.75rem;
        margin: 0 auto;
        padding: 0.875rem 0 0.5rem;
        [class*='BasketSummarystyle__BasketSummaryHeading'] {
          padding-right: 4rem;
          [class*='Headingstyle__Heading'] {
            font-size: 2.5rem;
          }
          [class*='Interactionstyle__Button'] {
            margin-top: 0.375rem;
          }
        }
        [class*='BasketSummarystyle__Sandwiches'] {
          height: 4.25rem;
          [class*='Sandwichstyle__Sandwich'] {
            [class*='Sandwichstyle__TextWrapper'] {
              position: relative;
            }
            [class*='Sandwichstyle__MainContent'] {
              [class*='Sandwichstyle__TextWrapper'] {
                top: 0;
              }
            }
            [class*='Headingstyle__Heading'] {
              [class*='Spanstyle__Span'] {
                font-size: 1.313rem;
                position: relative;
                color: var(--mineShaft);
              }
              [class*='Spanstyle__Span'],
              [class*='Sandwichstyle__TextWrapper'] {
                top: -0.25rem;
              }
            }
          }
        }
        [class*='BasketSummarystyle__ButtonContainer'] {
          [class*='Interactionstyle__Button'] {
            width: 14.5rem;
          }
        }
      }
      [class*='BasketSummarystyle__ButtonContainer'] {
        [class*='Interactionstyle__Button'] {
          min-width: fit-content;
          line-height: 1rem;
        }
      }
    }
  }
`;
