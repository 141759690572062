import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { rem } from 'polished';

import formatCurrency from '@/helpers/formatCurrency';

import { SPriceBig } from './SWBasket.styles';
import { TPrice } from './SWBasket.types';

export const Price = forwardRef<BoxProps & TPrice, 'div'>(
  ({ value = 0, isTotalSection, priceFontSize, ...props }, ref) => {
    const formatValue = (value: number | string) =>
      String(value).split('.').length > 1 ? formatCurrency(Number(value)) : value;

    return (
      <Box
        ref={ref}
        {...props}
        as="span"
        fontSize={isTotalSection ? rem('14px') : rem('16px')}
        letterSpacing={isTotalSection ? rem('0.4px') : 0}
        lineHeight={5}
        mt={{ lg: 4 }}
        fontWeight="bold"
      >
        &euro;
        <SPriceBig mr={isTotalSection ? 0 : rem('4px')} size={priceFontSize}>
          {value ? formatValue(value) : '-'}
        </SPriceBig>
        {isTotalSection && <br />}
        <Box as="span" mt={{ base: -2, md: 0 }} fontWeight="normal">
          /(ex VAT)
        </Box>
      </Box>
    );
  }
);
