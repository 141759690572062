import { Box, Heading, Stack, useBoolean } from '@chakra-ui/react';
import Image from '@vfuk/core-image';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import Paragraph from '@vfuk/core-paragraph';
import Toggle from '@vfuk/core-toggle';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { DetailsAndPricing } from '../../DetailsAndPricing';
import ExtraInformation from '../../ExtraInformation';
import { InfoAndCta } from '../../InfoAndCta';
import SWBundleIncludes from '../../SWBundleIncludes';
import { CardHeading, ImageWrap, LoadingWrapper, WrapToggle } from '../../SWPricingCard.styles';
import { DetailsAndPricingProps } from '../../SWPricingCard.types';
import { SWStandardCardProps } from './SWStandardCard types';

const SWStandardCard: FC<SWStandardCardProps> = ({
  item,
  mainItem,
  selectedItem,
  setToggleStatus,
  toggleStatus,
  itemStepName,
  shouldChangeTheme,
  mergedProduct,
  enableToggleOption,
  pathname,
  extraInformation,
  toggleDescription,
}) => {
  const { width } = useWindowSize();
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();
  const { bundleIncludes, addOn, thumbnailIcon } = selectedItem;
  const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';

  return (
    <>
      {selectedItem?.dxlInformation ? (
        <CardHeading pb={rem('14px')} pt={{ base: rem('27px'), md: rem('21px') }} px={5}>
          <Stack direction="row" spacing={5}>
            {thumbnailIconSrc && (
              <ImageWrap>
                <Image
                  alt={thumbnailIcon?.title || ''}
                  sm={{
                    src: thumbnailIconSrc,
                    width: '48px',
                    height: '48px',
                  }}
                  md={{
                    src: thumbnailIconSrc,
                    width: '64px',
                    height: '64px',
                  }}
                />
              </ImageWrap>
            )}
            <Box minH={rem('90px')}>
              <Heading
                as="h2"
                color={shouldChangeTheme ? 'white' : 'mineShaft'}
                fontSize={width >= 768 ? rem('28px') : rem('24px')}
                fontWeight="bold"
                lineHeight={{ base: rem('30px'), md: rem('36px') }}
                mb={0}
              >
                {selectedItem.dxlInformation?.name}
              </Heading>
            </Box>
          </Stack>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}
      {selectedItem.dxlInformation && selectedItem.detailAndPricing && (
        <>
          <Paragraph size={2} weight={1} justify="left">
            {selectedItem?.shortDescription?.shortDescription}
          </Paragraph>
          <DetailsAndPricing
            itemStepName={itemStepName}
            item={selectedItem}
            color={shouldChangeTheme ? 'white' : undefined}
            useSimplePrice={mergedProduct}
            {...{ selectedEdition, setSelectedEdition }}
          />
        </>
      )}
      {enableToggleOption && !pathname.includes('configuration') && (
        <WrapToggle toggleStatus={toggleStatus}>
          <Toggle
            id="align-left-toggle"
            name="align-left-toggle"
            value="toggle-11"
            align="left"
            isActive={toggleStatus}
            onChange={() => setToggleStatus.toggle()}
            dataSelectorPrefix={selectedItem.dxlInformation?.name.toLowerCase()}
          >
            {mergedProduct ? (
              <span>
                PREMIUM SUBSCRIPTION
                <small>{toggleDescription}</small>
              </span>
            ) : (
              <span>
                OPT OUT OF SECURITY
                <small>Remove security assessment from bundle</small>
              </span>
            )}
          </Toggle>
        </WrapToggle>
      )}
      {bundleIncludes && (
        <SWBundleIncludes
          title="Includes"
          items={bundleIncludes}
          backgroundColor={shouldChangeTheme ? '#8bc6d0' : undefined}
        />
      )}
      {!pathname.includes('configuration') && addOn && (
        <SWBundleIncludes
          title="Optional Add-On"
          items={addOn}
          backgroundColor={shouldChangeTheme ? '#00697C' : 'white'}
          color={shouldChangeTheme ? 'white' : undefined}
        />
      )}
      {extraInformation && <ExtraInformation extraInformation={extraInformation} />}
      {selectedItem.dxlInformation && (
        <InfoAndCta
          {...{ mainItem, itemStepName }}
          item={{ ...selectedItem, selectedEdition }}
          buttonStyling={{
            backgroundColor: shouldChangeTheme ? 'white' : undefined,
            textColor: shouldChangeTheme ? 'black' : undefined,
          }}
          useSimplePrice={mergedProduct}
        />
      )}
    </>
  );
};

export default SWStandardCard;
