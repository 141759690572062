import { Slide, useDisclosure } from '@chakra-ui/react';
import BasketSummary from '@source-web/basket-summary';
import { Item, SectionProps } from '@vfuk/core-basket-flyout/dist/BasketFlyout.types';
import { BasketSummary as BasketSummaryProps } from '@vfuk/core-basket-summary/dist/BasketSummary';
import { withPrefix } from 'gatsby';
import { useState } from 'react';

import { useAppSelector } from '@/store';

import { mockBasketData } from './basketDataRemovableItems.mock';
import { BasketSummaryWrapper } from './SWBasketSummary.styles';
import { BasketDataProps, SWBasketSummaryProps } from './SWBasketSummary.types';
import { useTranslation } from 'react-i18next';

export const CONTENTFUL_SECTION_SW_BASKET_SUMMARY = 'ContentfulSectionSwBasketSummary';

const SWBasketSummary = ({ flyoutButtonText }: SWBasketSummaryProps) => {
  const { t } = useTranslation();
  const userBasket = useAppSelector(({ userBasket }) => userBasket.data);
  const { user } = useAppSelector(({ userSession }) => userSession);

  //Use Mock data for now
  const [storeData, setStoreData] = useState<BasketDataProps>(mockBasketData);
  const [fakeError, setFakeError] = useState<boolean>(false);

  const { isOpen } = useDisclosure({
    isOpen: Boolean(user && userBasket.items.length > 0),
  });

  const removeItem: BasketSummaryProps['props']['onRemoveClick'] = (id: string) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (fakeError) {
          return reject(true);
        }
        const newBasketData = { ...storeData };
        newBasketData.sections = newBasketData.sections
          ?.map((section: SectionProps) => {
            return {
              ...section,
              items: section.items.filter(item => item.id !== id),
            };
          })
          .filter((section: SectionProps) => {
            return section.items.length;
          });
        setStoreData(newBasketData);
        resolve(true);
      }, 2000);
    });
  };

  const undo: BasketSummaryProps['props']['onUndoClick'] = (id: string) => {
    return new Promise((resolve, reject) => {
      let foundItem: Item | undefined;
      let foundSection: SectionProps | undefined;

      setTimeout(() => {
        if (fakeError) {
          return reject(true);
        }
        foundItem = undefined;
        foundSection = undefined;
        mockBasketData.sections?.forEach((section: SectionProps) => {
          const item = section.items.find((item: Item) => {
            return item.id === id;
          });
          if (item) {
            foundItem = item;
            foundSection = section;
          }
        });
        const newBasketData = { ...storeData };
        let addedItem = false;
        newBasketData.sections = newBasketData.sections?.map((section: SectionProps) => {
          const items = section.items;
          if (section.title === foundSection?.title) {
            foundItem && items.push(foundItem);
            addedItem = true;
          }
          return {
            ...section,
            items,
          };
        });
        if (!addedItem && foundSection) newBasketData.sections?.push(foundSection);
        setStoreData(newBasketData);
        resolve(true);
      }, 2000);
    });
  };

  return (
    <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
      <BasketSummaryWrapper>
        <BasketSummary
          priceSuffix={t('priceSuffix')}
          summaryButton={{
            href: withPrefix('/marketplace/product-list/basket'),
            text: t('goToBasket'),
          }}
          flyoutButton={{ text: flyoutButtonText || '' }}
          sections={storeData.sections}
          price={{ upfront: userBasket.total?.upfront, monthly: userBasket.total?.monthly }}
          onRemoveClick={removeItem}
          onUndoClick={undo}
          unit={userBasket?.items.length > 0 ? userBasket.items[0].detailAndPricing.currencySymbol : ""}
        />
      </BasketSummaryWrapper>
    </Slide>
  );
};

export default SWBasketSummary;
