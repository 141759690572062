import { Box, Flex } from '@chakra-ui/react';
import ButtonWithIcon from '@vfuk/core-button-with-icon';
import IconStepper from '@vfuk/core-icon-stepper';
import { IconStepperProps } from '@vfuk/core-icon-stepper/dist/IconStepper.types';
import { Tab, Tabs } from '@vfuk/core-tabs';
import axios from 'axios';
import { withPrefix } from 'gatsby';
import { rem } from 'polished';
import { FC, useEffect, useState } from 'react';

import Container from '@/components/Global/Container';
import PricingCardCarousel from '@/components/pageBuilder/Sections/PricingCardCarousel';
import { TPricingCardCarousel } from '@/components/pageBuilder/Sections/PricingCardCarousel/PricingCardCarousel.types';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';

import {
  ButtonsWrapper,
  CancelButton,
  HeadingWrapper,
  NextButton,
  StepperWrapper,
  TabsWrapper,
} from './SWConfigureBundle.styles';
import { SWConfigureBundleProps } from './SWConfigureBundle.types';

const SWConfigureBundle: FC<SWConfigureBundleProps> = ({
  id,
  contentful_id,
  backgroundColor,
  title,
  bundleContent,
  buttonDestinationLink,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [steps, setSteps] = useState<IconStepperProps['steps']>([]);

  const [countStep, setCountStep] = useState(0);
  const [itemStepName, setItemStepItemName] = useState<TPricingCardCarousel['itemStepName']>();

  const disableSteps = bundleContent?.disableSteps;

  const items = bundleContent && bundleContent.items.map(item => item);
  const content = items?.map(item => item.content);

  const [activeTab, setActiveTab] = useState<string>(`${items?.[0].id}-${items?.[0].contentful_id}`);
  const [productsWithDxlInfo, setProductsWithDxlInfo] = useState<TPricingCardCarousel['products']>([]);
  const [cardItems, setCardItems] = useState<any>([]);
  const { setErrorInfo, toggleModal } = useModalContext();

  const handleNextClickButton = () => {
    if (items && items.length > 0) {
      setCountStep(countStep + 1);
      setCurrentStep(currentStep + 1);

      const { id, contentful_id, name } = items[countStep + 1];
      setActiveTab(`${id}-${contentful_id}`);
      setItemStepItemName(name); // TODO: temporary solution for displaying notification message
    }
  };

  const handleBackClickButton = () => {
    if (items && items.length > 0 && countStep !== 0) {
      setCountStep(countStep - 1);
      setCurrentStep(currentStep - 1);

      const { id, contentful_id, name } = items[countStep - 1];
      setActiveTab(`${id}-${contentful_id}`);
      setItemStepItemName(name); // TODO: temporary solution for displaying notification message
    }
  };

  useEffect(() => {
    if (!itemStepName && items && items.length > 0) {
      setItemStepItemName(items[0].name);
    }
  }, [items]);

  useEffect(() => {
    Promise.all(productsWithDxlInfo).then(cards => {
      setCardItems({
        cards,
      });
    });
  }, [productsWithDxlInfo]);

  useEffect(() => {
    try {
      const productsIds = content?.map(item => item[0].productId);
      const productsWithEditions = content?.flatMap(async product => {
        product[0].dxlInformation = undefined;
        if (productsIds?.includes(product[0].productId)) {
          const newProduct = await retrieveProduct(product[0].productId);
          if (!axios.isAxiosError(newProduct) && newProduct.status === 200 && newProduct.data && newProduct.data.id) {
            product[0].dxlInformation = newProduct.data.dxlInformation;
          } else {
            setErrorInfo({
              ...(axios.isAxiosError(newProduct) && {
                statusType: newProduct.response?.data?.error,
                statusCode: newProduct.response?.data?.code || newProduct.response?.status,

              }),
              requestUrl: 'catalogues',
            });
            toggleModal(true, 'Error');
          }
          if (product[0].productsFreeOfCharge && product[0].productsFreeOfCharge.length > 0) {
            product[0].productsFreeOfCharge?.forEach(async (item, index) => {
              const newSubProduct = await retrieveProduct(item.productId);
              if (
                !axios.isAxiosError(newSubProduct) &&
                newSubProduct.data &&
                newSubProduct.data.id &&
                product[0].productsFreeOfCharge
              ) {
                product[0].productsFreeOfCharge[index].dxlInformation = undefined;
                const data = newSubProduct.data;
                product[0].productsFreeOfCharge[index] = {
                  ...item,
                  dxlInformation: data.dxlInformation,
                };
              } else {
                setErrorInfo({
                  ...(axios.isAxiosError(newSubProduct) && {
                    statusType: newSubProduct.response?.data?.error,

                    statusCode: newSubProduct.response?.data?.code || newSubProduct.response?.status,

                  }),
                  requestUrl: 'catalogues',
                });
                toggleModal(true, 'Error');
              }
            });
          }
          return product;
        }
      }) as TPricingCardCarousel['products'];

      setProductsWithDxlInfo(productsWithEditions);
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      setSteps(
        items.map(({ name, iconName }, index) => ({
          title: iconName === 'bundles' ? 'Bundle' : name,
          icon: { name: iconName },
          ...(index + 1 < currentStep && { state: 'success' }),
        })) as IconStepperProps['steps']
      );
    }
  }, [currentStep]);

  return (
    <Box
      as="section"
      id={`${id}-${contentful_id}`}
      backgroundColor={backgroundColor}
      py={{ base: rem('30px'), md: 13 }}
    >
      <Container>
        <HeadingWrapper textAlign="center">
          <Box
            as="h2"
            color="mineShaft"
            fontSize={{ base: 7, md: 10 }}
            fontWeight="bold"
            letterSpacing="wide"
            lineHeight={{ base: 8, md: rem('46px') }}
            mb="0"
          >
            Configure the Bundle
          </Box>

          <Box
            as="h3"
            color="mineShaft"
            fontSize={{ base: 'xl', md: '2xl' }}
            lineHeight={{ base: 7, md: rem('30px') }}
            mt={{ md: 2 }}
            mb={0}
          >
            {title}
          </Box>
        </HeadingWrapper>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          mt={disableSteps ? { base: 8, md: `${rem('-84px')}` } : { md: rem('50px'), lg: 12 }}
        >
          <ButtonsWrapper display={{ base: 'none', md: 'flex' }}>
            <CancelButton>
              <ButtonWithIcon
                icon={{
                  name: 'chevron-left',
                  justify: 'left',
                }}
                inverse
                text={countStep === 0 ? 'Cancel' : 'Back'}
                {...(countStep === 0 && { href: withPrefix(`${buttonDestinationLink}`) })}
                appearance="secondary"
                onClick={handleBackClickButton}
              />
            </CancelButton>
          </ButtonsWrapper>

          {!disableSteps && (
            <StepperWrapper stepsLength={steps.length}>
              <Box w={'full'} mt={{ base: rem('42px'), md: 0 }} mb={{ base: rem('46px'), md: 0 }}>
                {steps.length > 0 && <IconStepper currentStep={currentStep} steps={steps} hideControls />}
              </Box>
            </StepperWrapper>
          )}

          {!disableSteps ? (
            <ButtonsWrapper direction="row" justify="space-between">
              <CancelButton display={{ md: 'none' }} width={{ md: '50%' }}>
                <ButtonWithIcon
                  icon={{
                    name: 'chevron-left',
                    justify: 'left',
                  }}
                  inverse
                  text={countStep === 0 ? 'Cancel' : 'Back'}
                  {...(countStep === 0 && { href: withPrefix(`${buttonDestinationLink}`) })}
                  appearance="secondary"
                  onClick={handleBackClickButton}
                />
              </CancelButton>
              <NextButton
                sx={{
                  ...(countStep === steps.length - 1 && {
                    a: {
                      p: 0,
                      span: {
                        display: 'none',
                      },
                    },
                  }),
                }}
              >
                <ButtonWithIcon
                  {...(countStep === steps.length - 1 && { styles: { borderColor: '#080808' } })}
                  icon={{
                    name: 'chevron-right',
                    justify: 'right',
                  }}
                  text={countStep === steps.length - 1 ? 'Go to basket' : 'Next'}
                  appearance={countStep === steps.length - 1 ? 'primary' : 'secondary'}
                  {...(countStep < steps.length - 1 && { onClick: handleNextClickButton })}
                  {...(countStep === steps.length - 1 && { href: withPrefix('/marketplace/product-list/basket') })}
                  htmlAttributes={{
                    dataAttributes: {
                      'data-selector': `${
                        countStep === steps.length - 1 ? 'config basket button' : 'config next button'
                      }`,
                    },
                  }}
                />
              </NextButton>
            </ButtonsWrapper>
          ) : (
            <ButtonsWrapper direction="row" justify="space-between" display={{ base: 'flex', md: 'none' }}>
              <CancelButton width={{ md: '50%' }}>
                <ButtonWithIcon
                  icon={{
                    name: 'chevron-left',
                    justify: 'left',
                  }}
                  inverse
                  text={'Cancel'}
                  {...{ href: withPrefix(`${buttonDestinationLink}`) }}
                  appearance="secondary"
                  onClick={handleBackClickButton}
                />
              </CancelButton>
              <NextButton
                sx={{
                  button: {
                    p: 0,
                    span: {
                      display: 'none',
                    },
                  },
                }}
              >
                <ButtonWithIcon
                  {...{ styles: { borderColor: '#080808' } }}
                  icon={{
                    name: 'chevron-right',
                    justify: 'right',
                  }}
                  text="Go to basket"
                  appearance="primary"
                  onClick={handleNextClickButton}
                  {...{
                    htmlAttributes: {
                      dataAttributes: {
                        'data-selector': 'config basket button',
                      },
                    },
                  }}
                />
              </NextButton>
            </ButtonsWrapper>
          )}
        </Flex>
      </Container>

      <TabsWrapper
        as="section"
        pt={disableSteps ? { base: 8, md: `${rem('90px')}` } : { base: `${rem('40px')}`, md: `${rem('50px')}` }}
        step-number={countStep}
        step-name={steps[countStep]?.title || ''}
      >
        {productsWithDxlInfo && !!itemStepName && (
          <Tabs appearance="secondary" externallySetTabId={activeTab}>
            {items?.map(({ id, contentful_id, name, content, buttonsAction, buttonsAppearance }) => (
              <Tab key={`${id}-${contentful_id}`} id={`${id}-${contentful_id}`}>
                <PricingCardCarousel
                  id={id}
                  contentful_id={contentful_id}
                  mainItem={items[0].content[0]}
                  name={name}
                  buttonsAction={buttonsAction}
                  buttonsAppearance={buttonsAppearance}
                  products={content}
                  itemStepName={itemStepName}
                />
              </Tab>
            ))}
          </Tabs>
        )}
      </TabsWrapper>
    </Box>
  );
};

export default SWConfigureBundle;
