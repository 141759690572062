import IconButton from '@vfuk/core-icon-button';
import { FC } from 'react';

import { isInBasket, removeFromBasket, useAppDispatch, useAppSelector } from '@/store';

import { DeleteItemButton, DeleteItemWrapper } from './SWBasket.styles';
import { TDeleteItem } from './SWBasket.types';

export const DeleteItem: FC<TDeleteItem> = ({ item }) => {
  const dispatch = useAppDispatch();
  const userBasket = useAppSelector(state => state.userBasket.data);

  const handleRemoveItem = () => {
    if (isInBasket(userBasket, item)) {
      dispatch(removeFromBasket(item));
    }
  };

  return (
    <DeleteItemWrapper w="full">
      <DeleteItemButton direction="row" justify={{ base: 'flex-end', md: 'flex-start' }} mt={{ base: 0, md: 5, lg: 0 }}>
        <IconButton icon={{ name: 'delete' }} appearance="alt1" onClick={handleRemoveItem} />
      </DeleteItemButton>
    </DeleteItemWrapper>
  );
};
