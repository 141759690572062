import React, {FC} from 'react';
import {SWQuickLinksProps} from "@/components/pageBuilder/Sections/SWSectionQuickLinks/SWSectionQuickLinks.types";
import {
    LinkWrapper,
    QuickLinksWrapper,
    LinkItem
} from "@/components/pageBuilder/Sections/SWSectionQuickLinks/SWSectionQuickLinks.styles";
import Link from "@vfuk/core-link";
import {getImage, getSrc} from "gatsby-plugin-image";
import Image from "@vfuk/core-image";

const SWSectionQuickLinks: FC<SWQuickLinksProps> = ({content}: SWQuickLinksProps) => {
    return (
        <QuickLinksWrapper>
            <LinkWrapper>
                {content.map(item => (
                    <Link href={item.url}>
                        <LinkItem>
                            <Image
                                alt="image"
                                sm={{
                                    src: getSrc(item.icon) || getImage(item.icon)?.placeholder?.fallback,
                                    width: '80px',
                                    height: '80px',
                                }}
                            />
                            {item.name}
                        </LinkItem>
                    </Link>
                ))}
            </LinkWrapper>
        </QuickLinksWrapper>
    );
};
export default SWSectionQuickLinks;
