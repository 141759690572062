import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { TPricingCardCarousel } from './PricingCardCarousel.types';

export const CarouselWrapper = styled.div<Pick<TPricingCardCarousel, 'stackableProduct'>>`
  width: 100%;
  max-width: ${rem('1180px')};
  margin: 0 auto;

  .slick-slider {
    user-select: auto;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
    height: 100%;

    > div {
      max-width: ${rem('345px')};

      @media (min-width: ${props => props.theme.breakpoints.md}px) {
        max-width: ${rem('382px')};
      }
    }
  }

  [class*='CarouselArrowstyle__CarouselArrow-sc'] {
    z-index: 4;
    box-shadow: 0 ${rem('2px')} ${rem('4px')} 0 rgba(203, 203, 203, 1);
    background: var(--vodafone-colors-white);
    width: ${rem('50px')};
    height: ${rem('50px')};

    [class*='Interactionstyle__Button-sc'] {
      border: none;

      [class*='Iconstyle__Icon-sc'] {
        svg {
          width: ${rem('30px')};
          height: ${rem('30px')};

          :focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.md}px) {
      &[direction='next'] {
        left: 99%;
      }
      &[direction='previous'] {
        right: 99%;
      }
    }
  }

  [class*='FunctionalCarouselstyle__CarouselDotPagerList-sc'] {
    text-align: center;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      margin-bottom: ${rem('64px')};
    }
  }
  [class*='FunctionalCarouselstyle__Carousel-sc'] {
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin: 0;
    }
  }
`;

export const NotificationWrapper = styled(Flex)`
  [class*='StateNotificationstyle__IconWrapper-sc'] {
    align-items: center;
    display: flex;
    padding: 0 ${rem('22px')};
  }

  [class*='StateNotificationstyle__ContentWrapper-sc'] {
    padding: ${rem('14px')} ${rem('24px')} ${rem('13px')} ${rem('16px')};

    [class*='StateNotificationstyle__HeadingWrapper-sc'] {
      [class*='Headingstyle__Heading-sc'] {
        color: ${({ theme }) => theme.colors?.mineShaft};
        font-weight: 400;
      }
    }
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${rem('30px')};
  flex-direction: column;
`;
