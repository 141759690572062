import { Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react';
import Paragraph from '@vfuk/core-paragraph';

import { useModalContext } from '@/hooks/modal/useModal';

import { AnimationSurround } from './LoadingCheckoutModal.styles';

const LoadingCheckoutModal = () => {
  const { onClose } = useDisclosure();
  const { isLoadingCheckoutModalOpen } = useModalContext();

  return (
    <Modal isCentered isOpen={isLoadingCheckoutModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="max-content">
        <ModalBody>
          <AnimationSurround h="100px">
            <Spinner size="xl" />
            <Paragraph size={2} weight={3}>
              Please wait for some time for check out ...
            </Paragraph>
          </AnimationSurround>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingCheckoutModal;
