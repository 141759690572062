import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const ButtonsWrapper = styled(Flex)``;

export const CancelButton = styled(ButtonsWrapper)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    background: transparent;
    border: ${rem('1px')} solid;
    border-color: #f4f4f4;
    color: #f4f4f4;
    cursor: pointer;
    font-size: ${rem('20px')};
    min-width: auto;
    width: ${rem('50px')};
    height: ${rem('50px')};

    &:hover {
      border: ${rem('1px')} solid;

      [class*='Iconstyle__Icon'] * {
        stroke: #f4f4f4;
      }
    }

    [class*='Iconstyle__Icon'] {
      margin-right: 0;

      @media only screen and (min-width: ${rem('768px')}) {
        margin-right: ${rem('4px')};
      }

      svg {
        stroke: #f4f4f4;
        width: ${rem('20px')};
        height: ${rem('20px')};
      }
    }

    @media only screen and (max-width: ${rem('1440px')}) {
      font-size: 0;
    }

    @media only screen and (min-width: ${rem('1441px')}) {
      width: ${rem('180px')};
    }
  }
`;

export const CtaButton = styled(ButtonsWrapper)`
  width: 100%;
`;
