import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { NextStepProps } from './SWConfigureBundleOptions.types';

export const HeadingWrapper = styled(Flex)``;

export const headingFlexStyles = {
  mt: { base: rem('35px'), md: 0 },
  px: { md: rem('68px') },
  w: 'full',
  sx: {
    h2: {
      fontSize: { base: '4xl', md: 10 },
      lineHeight: { base: rem('41px'), md: rem('46px'), lg: rem('48px') },
      mb: 0,
      whiteSpace: { base: 'normal', lg: 'pre-wrap' },
    },
    h3: {
      fontSize: { base: '2xl', md: rem('29px') },
      letterSpacing: { base: 0, md: 'tight' },
      lineHeight: rem('48px'),
      mb: 0,
      mt: { base: 5, md: rem('38px') },
    },
    p: {
      m: `0 0 ${rem('25px')}`,
      '&:last-child': {
        m: 0,
      },
    },
    'b, strong': {
      letterSpacing: 'wide',
    },
  },
};

export const ButtonsWrapper = styled(Flex)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    font-size: ${rem('20px')};
    min-width: auto;
    width: ${rem('50px')};
    height: ${rem('50px')};

    @media only screen and (min-width: ${rem('1441px')}) {
      width: ${rem('180px')};
    }

    [class*='Iconstyle__Icon'] {
      margin-right: 0;

      @media only screen and (min-width: ${rem('768px')}) {
        margin-right: ${rem('4px')};
      }

      svg {
        width: ${rem('20px')};
        height: ${rem('20px')};
      }
    }
  }
`;

export const CancelButton = styled(ButtonsWrapper)`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    @media only screen and (max-width: ${rem('1440px')}) {
      font-size: 0;
    }
  }
`;

export const NextButton = styled(ButtonsWrapper)<Pick<NextStepProps, 'isEnabled'>>`
  [class*='Buttonstyle__Button-sc'],
  [class*='ButtonWithIconstyle__ButtonWithIcon-sc'] {
    background: ${({ isEnabled, theme }) => (isEnabled ? theme.color.primary1.default : '#bdbbbb')};
    border-color: ${({ isEnabled, theme }) => (isEnabled ? theme.color.primary1.default : '#bdbbbb')};
    color: ${({ theme }) => theme.color.monochrome1.default};
    width: ${rem('345px')};

    @media only screen and (min-width: ${rem('992px')}) {
      width: ${rem('380px')};
    }
  }
`;
