import styled from '@emotion/styled';
import { rem } from 'polished';

type CarouselWrapperProps = {
  position?: string;
};

export const CarouselWrapper = styled.section<CarouselWrapperProps>`
  background: ${({ theme }) => theme.color.primary2.default};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 46%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg + 1}px) {
    width: 59%;
    [class*='Containerstyle__Container'] {
      box-shadow: none;
    }
  }

  & li {
    background: var(--DustyGray);
  }

  .slick-slider {
    .slick-track {
      .slick-slide:not(.slick-active) {
        visibility: initial;
      }

      .slick-slide {
        width: fit-content;
        display: inline-block;
        padding: 0 ${rem('10px')} 0 0;
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          padding: 0 0 0 ${rem('16px')};
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          padding: ${({ position }) =>
            position === 'left' ? `0 0 0 ${rem('20px')}` : `0 ${rem('20px')} 0 ${rem('1px')}`};
        }
        > div {
          margin: 0;
        }
      }
      .slick-slide:first-of-type {
        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
            theme.breakpoints.lg - 1}px) {
          padding: ${({ position }) => (position === 'left' ? `0 0 0 ${rem('16px')}` : `0`)};
        }
      }
    }
  }

  [class*='FunctionalCarouselstyle__Carousel'] {
    margin: 0;
  }

  [class*='CarouselDotPagerList'] {
    display: none;
  }

  [class*='CarouselArrowstyle__CarouselArrow'] {
    z-index: 3;
    &[direction='next'] {
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        left: 97%;
      }
    }

    [class*='IconButtonstyle__IconButton-sc'] {
      border: none;
    }
  }
`;
