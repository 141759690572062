import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';

import { WrapperSectionProps } from './SWPricingContent.types';

export const PricingContentWrapper = styled.div<WrapperSectionProps>`
  align-items: center;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'unset')};
  background-image: url(${({ backgroundImage }) => (backgroundImage ? getSrc(backgroundImage) : 'none')});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 0 ${rem('16px')} ${rem('50px')};
  padding-bottom: ${({ productsLength }) => (productsLength > 3 ? rem('42px') : rem('50px'))};
  position: relative;
  margin-bottom: ${rem('24px')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 ${rem('0px')} ${rem('54px')};
    margin-bottom: ${rem('0px')};
    margin-bottom: ${rem('24px')};
  }
  > p[class*='Paragraphstyle__Paragraph-sc'] {
    color: #e60000;
    font-size: ${rem('14px')};
    line-height: ${rem('16px')};
    text-align: center;
    height: ${rem('24px')};
    bottom: ${rem('32px')};
    display: flex;
    flex-direction: column;
    gap: ${rem('2px')};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      bottom: 2.5rem;
      gap: ${rem('4px')};
      padding: 0 ${rem('8px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
      bottom: 2.5rem;
      gap: ${rem('4px')};
      padding: 0 ${rem('13px')};
    }
  }
`;

export const Title = styled.h2`
  color: var(--mineShaft);
  font-size: ${rem('40px')};
  letter-spacing: ${rem('-0.4px')};
  line-height: ${rem('48px')};
  margin-bottom: 0;
  padding-block: ${rem('52px')} ${rem('48px')};
  text-align: center;
`;

export const Icons = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  padding: ${rem('32px')} 0 ${rem('8px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 0px ${rem('30px')};
    grid-template-columns: repeat(4, 1fr);
    padding-top: ${rem('42px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 0px ${rem('74px')};
  }
`;

export const IconWithText = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: ${rem('180px')};
  margin-bottom: ${rem('24px')};
  padding: 0 ${rem('30px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0;
  }

  [class*='Iconstyle__Icon-sc'] {
    svg {
      stroke-width: ${rem('2px')};
      width: ${rem('40px')};
      height: ${rem('40px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        width: ${rem('80px')};
        height: ${rem('80px')};
      }
    }
  }

  h2 {
    font-size: ${rem('18px')};
    font-weight: 700;
    letter-spacing: ${rem('0.6px')};
    line-height: ${rem('20px')};
    margin-top: ${rem('8px')};
    text-align: center;
  }
`;
