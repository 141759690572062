import { Box, chakra, ChakraProvider, Flex, Stack, useRadio } from '@chakra-ui/react';
import Checkbox from '@vfuk/core-checkbox';
import { rem } from 'polished';
import { FC } from 'react';

import { TRadioAsCheckbox } from './RadioAsCheckbox.types';

const RadioAsCheckbox: FC<TRadioAsCheckbox> = ({ onCheckboxClick, ...props }) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  const handleCheckboxClick = () => {
    if (props.value && onCheckboxClick) {
      onCheckboxClick(String(props.value));
    }
  };

  return (
    <Box as="label">
      <input {...input} />
      <Stack
        {...checkbox}
        align="center"
        borderRadius="md"
        cursor="pointer"
        direction="row"
        spacing={{ base: rem('5px'), sm: rem('14px') }}
        _focus={{
          boxShadow: 'outline',
        }}
        data-selector="secondary options"
      >
        <Flex
          onClick={handleCheckboxClick}
          sx={{
            '[class*="Checkboxstyle__CheckboxLabel-sc"]': {
              cursor: 'pointer',
              'input:checked ~ [class*="Checkboxstyle__Checkbox-sc"]': {
                bg: '#00B0CA',
              },
              '[class*="Checkboxstyle__Checkbox-sc"]': {
                bg: 'none',
                borderRadius: rem('3px'),
                boxSize: { base: rem('14px'), md: 5 },
                mr: 0,
              },
            },
          }}
        >
          <Checkbox id={props.id || ''} name={props.name || ''} checked={!!props.isChecked} value={''}>
            <chakra.span display="none"></chakra.span>
          </Checkbox>
        </Flex>
        <Box as="span">{props.children}</Box>
      </Stack>
    </Box>
  );
};

export default RadioAsCheckbox;
