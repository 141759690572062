import styled from '@emotion/styled';
import { rem } from 'polished';

export const IncludesWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  background-color: #f2f2f2;
  color: var(--black);
  display: flex;
  align-items: left;
  padding: ${rem('20px')} 0;

  p {
    font-weight: 700;
  }

  [class*='SlotWrapperstyle__SlotWrapper-sc'] {
    padding: ${rem('5px')} ${rem('20px')};
    h2 {
      font-weight: 400;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const PriceDetailsWrapper = styled.div`
  h5 {
    font-size: ${rem('16px')};
    line-height: ${rem('15px')};
  }

  h6 {
    font-size: ${rem('46px')};
    letter-spacing: ${rem('2px')};
    line-height: ${rem('36px')};
    margin: 0;
    font-weight: var(--vodafone-fontWeights-bold);

    small {
      margin: 0 ${rem('-4px')} 0 ${rem('-2px')};
    }
  }
`;
