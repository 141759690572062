import CardCarousel from '@vfuk/core-card-carousel';
import Heading from '@vfuk/core-heading';
import { getImage, getSrc, getSrcSet } from 'gatsby-plugin-image';
import { FC } from 'react';

import { addLinkTestId } from '../../../../helpers/addTestId';
import {
  CarouselIconsImage,
  CarouselIconsPictureWrapper,
  CarouselIconsSource,
  CarouselSurround,
  CarouselWrapper,
} from './SWCarouselIcons.styles';
import { SWCarouselIconsProps } from './SWCarouselIcons.types';

const SWCarouselIcons: FC<SWCarouselIconsProps> = ({ title, subtitle, carouselImages }: SWCarouselIconsProps) => {
  return (
    <CarouselSurround>
      <CarouselWrapper aria-labelledby="carouselheading" role="region">
        <Heading level={2} justify={'center'} text={title} data-testid={addLinkTestId('carouselHeading', title)} />
        <Heading
          level={3}
          justify={'center'}
          text={subtitle}
          data-testid={addLinkTestId('carouselSubtitle', 'carouselSubtitle')}
        />
        <CardCarousel
          slidesToShow={{ sm: 2, md: 6, lg: 6, xl: 6 }}
          slidesToScroll={{ sm: 1, md: 1, lg: 1, xl: 1 }}
          // eslint-disable-next-line react/no-children-prop
          children={carouselImages.map((icon, index) => {
            const iconSrc = getSrc(icon) || getImage(icon)?.placeholder?.fallback || '';
            const iconSrcSet = getSrcSet(icon) || getImage(icon)?.placeholder?.fallback || '';

            return (
              <CarouselIconsPictureWrapper
                key={`carouselPicture_${index}`}
                data-testid={addLinkTestId(`carouselPicture_${index}`, icon.title)}
              >
                <CarouselIconsSource media="(min-width: 649px)" srcSet={iconSrcSet} />
                <CarouselIconsImage alt={icon.title} src={iconSrc} key={icon.title} />
              </CarouselIconsPictureWrapper>
            );
          })}
          srName={`${title} Carousel`}
        />
      </CarouselWrapper>
    </CarouselSurround>
  );
};

export default SWCarouselIcons;
