import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CardCarousel from '@vfuk/core-card-carousel';
import Heading from '@vfuk/core-heading';
import Icon from '@vfuk/core-icon';
import Image from '@vfuk/core-image';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { FC } from 'react';

import { addLinkTestId } from '@/helpers/addTestId';

import { AccordionIcon, CarouselCardsWrapper, ItemWrapper, Wrapper } from './SWAccordionCardCarousel.styles';
import { SWAccordionCardCarouselProps } from './SWAccordionCardCarousel.types';

const SWAccordionCardCarousel: FC<SWAccordionCardCarouselProps> = ({ name, items }: SWAccordionCardCarouselProps) => {
  const carouselContents = items
    ? items.map((card, index) => {
        const cardImageSrc = getSrc(card.cardImage) || getImage(card.cardImage)?.placeholder?.fallback || '';

        return (
          <CarouselCardsWrapper
            key={`carouselCard-${index}-${card.id}`}
            data-testid={addLinkTestId(`carouselCard_${index}`, card.heading)}
          >
            <Image
              alt={card.cardImage?.title || ''}
              sm={{
                src: cardImageSrc,
                width: 'auto',
                height: '180px',
              }}
              md={{
                src: cardImageSrc,
                width: 'auto',
                height: '150px',
              }}
              lg={{
                src: cardImageSrc,
                width: 'auto',
                height: '180px',
              }}
            />
            <Accordion allowMultiple mb={rem('10px')} mx={rem('20px')} flexGrow={1} display="flex" flexDir="column">
              <AccordionItem flexGrow={1}>
                {({ isExpanded }) => (
                  <ItemWrapper direction="column" h="full">
                    <div className="heading-subheading-block">
                      <Heading level={4} weight={1} text={card.heading} />
                      <Box className="sub-heading" as="span">
                        {card.subheading}
                      </Box>
                    </div>
                    <AccordionButton
                      justifyContent="center"
                      _hover={{ background: '#fff' }}
                      _focus={{ outline: 'none' }}
                    >
                      <AccordionIcon>
                        {isExpanded ? 'Show less' : 'Show more'}
                        <Box ml={'11px'} transform={isExpanded ? 'rotate(180deg)' : 'none'} transition="0.2s ease-out">
                          <Icon appearance="secondary" group="system" name={'chevron-down'} />
                        </Box>
                      </AccordionIcon>
                    </AccordionButton>
                    <AccordionPanel p={0}>
                      <p className="accordion-body">
                        {card.accordionBody?.raw && documentToReactComponents(JSON.parse(card.accordionBody.raw))}
                      </p>
                    </AccordionPanel>
                  </ItemWrapper>
                )}
              </AccordionItem>
            </Accordion>
          </CarouselCardsWrapper>
        );
      })
    : [];

  return (
    <Wrapper aria-labelledby="featureCardAccordionCardCarouselHeading" role="region">
      <CardCarousel
        slidesToShow={{ sm: 1, md: 3, lg: 3, xl: 3 }}
        slidesToScroll={{ sm: 1 }}
        // eslint-disable-next-line react/no-children-prop
        children={carouselContents}
        srName={`${name} Accordion Card Carousel`}
      />
    </Wrapper>
  );
};

export default SWAccordionCardCarousel;
