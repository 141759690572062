import { Box, Flex, FlexProps, forwardRef, Heading, UnorderedList } from '@chakra-ui/react';
import Icon from '@vfuk/core-icon';
import Image from '@vfuk/core-image';
import Toggle from '@vfuk/core-toggle';
import Tooltip from '@vfuk/core-tooltip';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { useState } from 'react';

import { AddonWrapToggle, BundleIncludesItem, LabelWrapper } from './SWPricingCard.styles';
import { SWBundleIncludesProps } from './SWPricingCard.types';

const SWBundleIncludes = forwardRef<FlexProps & SWBundleIncludesProps, 'div'>(
  ({ title, items, backgroundColor, color, showToggle, ...props }, ref) => (
    <Flex
      ref={ref}
      backgroundColor={backgroundColor || 'monochrome2.default'}
      direction="column"
      pt={4}
      pb={title === 'Add-On' ? 0 : 6}
      px={5}
      {...props}
    >
      <Heading
        as="h6"
        color={color || 'mineShaft'}
        fontSize={rem('17px')}
        letterSpacing={rem('0.6px')}
        lineHeight={rem('18px')}
        my={0}
      >
        {title}
      </Heading>
      <UnorderedList styleType="none" m={0} mt={4} p={0} spacing={3}>
        {items?.map(({ id, name, slug, thumbnailIcon, includeDescription, shortDescription }, index) => {
          const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback;
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [toggleStatus, setToggleStatus] = useState<boolean>(false);
          const renderAddon = (toggle: boolean) => {
            return (
              <>
                {thumbnailIconSrc && (
                  <Box mr={rem('6px')} minW={rem('34px')} minH={rem('34px')}>
                    <Image
                      alt={thumbnailIcon?.title || ''}
                      sm={{
                        src: thumbnailIconSrc,
                        width: '34px',
                        height: '34px',
                      }}
                    />
                  </Box>
                )}
                {toggle ? (
                  <LabelWrapper>{includeDescription ? includeDescription : name}</LabelWrapper>
                ) : (
                  <>{includeDescription ? includeDescription : name}</>
                )}
                {shortDescription?.shortDescription && (
                  <Tooltip
                    id={`trigger-${id}-${slug}`}
                    appearance="secondary"
                    size={1}
                    text={shortDescription?.shortDescription || ''}
                    srText={shortDescription?.shortDescription || ''}
                  >
                    <Icon name="info-circle" group="system" size={1} />
                  </Tooltip>
                )}
              </>
            );
          };
          return (
            <BundleIncludesItem
              key={`${index}-${id}`}
              alignItems="center"
              color={color || 'mineShaft'}
              display="flex"
              fontSize="md"
              lineHeight={5}
            >
              {showToggle ? (
                <AddonWrapToggle toggleStatus={toggleStatus}>
                  <Toggle
                    align="left"
                    isActive={toggleStatus}
                    onChange={() => {
                      setToggleStatus(!toggleStatus);
                    }}
                  >
                    {renderAddon(true)}
                  </Toggle>
                </AddonWrapToggle>
              ) : (
                <>{renderAddon(false)}</>
              )}
            </BundleIncludesItem>
          );
        })}
      </UnorderedList>
    </Flex>
  )
);

export default SWBundleIncludes;
