import Button from '@vfuk/core-button';
import { withPrefix } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';

import {
  BannerImage,
  ButtonWrapper,
  ContentContainer,
  ContentWrapper,
  Description,
  Header,
  HeroBannerContainer,
  Wrapper,
} from './SWHeroBannerWithSubHeader.styles';
import { SWHeroBannerWithSubHeaderProps } from './SWHeroBannerWithSubHeader.types';

const SWHeroBannerWithSubHeader = ({
  textPosition,
  description,
  header,
  subHeader,
  backgroundImage,
  textColor,
  primaryButton,
}: SWHeroBannerWithSubHeaderProps) => {
  const backgroundImageSrc = getSrc(backgroundImage) || getImage(backgroundImage)?.placeholder?.fallback || '';
  const getCorrectTextPosition = (position: string | undefined) => {
    if (position === 'center') return 'center';
    if (position === 'right') return 'flex-end';
    if (position === 'left') return 'flex-start';
    return 'flex-end';
  };
  const correctTextPosition = getCorrectTextPosition(textPosition);

  return (
    <Wrapper data-selector="hero banner">
      <BannerImage src={backgroundImageSrc} textPosition={correctTextPosition} alt={backgroundImage.description} />
      <HeroBannerContainer textPosition={correctTextPosition}>
        <ContentContainer>
          <ContentWrapper textColor={textColor} textPosition={correctTextPosition}>
            <Header>{header}</Header>
            <Description>{description}</Description>
            <Header>{subHeader}</Header>
            <ButtonWrapper>
              <Button
                text={primaryButton?.label}
                appearance="secondary"
                inverse
                href={withPrefix(primaryButton?.url)}
                dataSelectorPrefix={header.toLowerCase()}
              />
            </ButtonWrapper>
          </ContentWrapper>
        </ContentContainer>
      </HeroBannerContainer>
    </Wrapper>
  );
};

export default SWHeroBannerWithSubHeader;
