import { rem } from 'polished';
import styled from 'styled-components';

export const InputContainer = styled.div<{ fullWidth: boolean | undefined }>`
  width: 100%;
  margin: ${rem('10px')} 0;
  padding: 0 ${rem('10px')};
  [class*='Checkboxstyle__CheckboxLabel-sc'] {
    font-size: ${rem('14px')};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: ${props => (props.fullWidth ? 100 : 50)}%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    [class*='FieldWrapperstyle__FieldWrapper-sc'] {
      width: 100%;
      max-width: 100%;
    }
  }
`;
