import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import SWSectionBreadcrumb from '@/components/Global/SWBreadcrumb';
import SWErrorModal from '@/components/Global/SWErrorModal';
import SWLoginModal from '@/components/Global/SWLoginModal';
import SWBasketSummary from '@/components/pageBuilder/Sections/SWBasketSummary';
import { CONTENTFUL_SECTION_SW_BASKET_SUMMARY } from '@/components/pageBuilder/Sections/SWBasketSummary/SWBasketSummary';
import SWHeroBannerWithSubHeader from '@/components/pageBuilder/Sections/SWHeroBannerWithSubHeader';
import SWProductList from '@/components/pageBuilder/Sections/SWProductList';
import SWSectionFAQs from '@/components/pageBuilder/Sections/SWSectionFAQs';
import SWSectionFiftyFiftyBanner from '@/components/pageBuilder/Sections/SWSectionFiftyFiftyBanner';
import SWSectionSelectAndSearchBar from '@/components/pageBuilder/Sections/SWSectionSelectAndSearchBar';
import MainLayout from '@/layouts/MainLayout';

import { ContentSectionProps } from '../../types/Contentful.types';
import {
  CONTENTFUL_SECTION_SW_BREADCRUMB,
  CONTENTFUL_SECTION_SW_ERROR_MODAL,
  CONTENTFUL_SECTION_SW_FAQS,
  CONTENTFUL_SECTION_SW_FIFTY_FIFTY_BANNER,
  CONTENTFUL_SECTION_SW_HERO_BANNER_WITH_SUB_HEADER,
  CONTENTFUL_SECTION_SW_LOGIN_MODAL,
  CONTENTFUL_SECTION_SW_PRODUCT_LIST,
  CONTENTFUL_SECTION_SW_QUICK_LINKS,
  CONTENTFUL_SECTION_SW_SELECT_AND_SEARCH_BAR,
} from '../page-builder-helpers';
import { ProductsTemplateProps } from './products.types';
import SWSectionQuickLinks from "@/components/pageBuilder/Sections/SWSectionQuickLinks";

const Products = ({ pageContext }: PageProps<ProductsTemplateProps, ProductsTemplateProps>) => {
  const { pageData } = pageContext;
  const { sections, seoDescription, seoTitle } = pageData;

  const RenderContent = (section: ContentSectionProps) => {
    switch (section.__typename) {
      case CONTENTFUL_SECTION_SW_QUICK_LINKS: {
        return <SWSectionQuickLinks key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BREADCRUMB: {
        return <SWSectionBreadcrumb key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FIFTY_FIFTY_BANNER: {
        return <SWSectionFiftyFiftyBanner key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_PRODUCT_LIST: {
        return <SWProductList key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_SELECT_AND_SEARCH_BAR: {
        return <SWSectionSelectAndSearchBar key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_BASKET_SUMMARY: {
        return <SWBasketSummary key={section.id} {...section} />;
      }
      case CONTENTFUL_SECTION_SW_HERO_BANNER_WITH_SUB_HEADER: {
        return <SWHeroBannerWithSubHeader key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_FAQS: {
        return <SWSectionFAQs key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_LOGIN_MODAL: {
        return <SWLoginModal key={section.id} {...section} />;
      }

      case CONTENTFUL_SECTION_SW_ERROR_MODAL: {
        return <SWErrorModal key={section.id} {...section} />;
      }

      default:
        return <></>;
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
      </Helmet>

      {sections?.map(section => (
        <RenderContent key={section.id} {...section} />
      ))}
    </MainLayout>
  );
};

export default Products;
