import { BoxProps, forwardRef } from '@chakra-ui/react';

import { SItem, SItemHeader } from './SWBasket.styles';
import { TItem } from './SWBasket.types';

export const Item = forwardRef<BoxProps & TItem, 'div'>(({ title, children, ...props }, ref) => (
  <SItem
    ref={ref}
    {...props}
    direction="column"
    align="flex-start"
    justifyContent={{ base: 'flex-end', md: 'flex-start' }}
    spacing={1}
  >
    {title && (
      <SItemHeader as="span" fontSize="xl">
        {title}
      </SItemHeader>
    )}
    {children}
  </SItem>
));
