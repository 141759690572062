import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

type TopNavProps = {
  user: string | undefined;
};

export const TopNavigationWrapper = styled(Box)<TopNavProps>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  [class*='ListItemstyle__ListItem-sc'] {
    [class*='TopLinkstyle__TopLink-sc'] {
      font-family: 'VodafoneLight';
      font-size: ${rem('16px')};
      font-weight: normal;
      display: block;
      padding: ${rem('5px')} ${rem('20px')};
      position: relative;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    [class*='MainBarstyle__MainBar-sc'] {
      padding: ${rem('7px')} 0px;
    }
    [class*='SimpleGridstyle__SimpleGridColumn'] {
      [aria-label='Vodafone-Logo'] {
        margin-left: ${rem('20px')};
        svg {
          width: ${rem('40px')};
          height: ${rem('40px')};
        }
      }

      [class*='MainBarstyle__MainBarContents'] {
        flex-direction: row-reverse;
        padding-right: ${rem('8px')};
      }
    }
  }

  [class*='button--sign-out'] {
    position: absolute;
    width: max-content;
    box-shadow: 0 ${rem('2px')} ${rem('4px')} rgb(0 0 0 / 30%);
    font-size: ${rem('18px')};
    width: ${rem('88px')};
    top: ${rem('-4px')};
    right: ${rem('13px')};
    padding: ${rem('9px')} ${rem('11px')};
    border-radius: ${rem('6px')};
    background-color: #fff;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    [class*='SimpleGridstyle__SimpleGridColumn'] {
      [aria-label='Vodafone-Logo'] {
        svg {
          width: ${rem('48px')};
          height: ${rem('48px')};
        }
      }
    }

    [class*='Overlaystyle__Overlay-sc'] {
      background: none;
    }

    [class*='Animatestyle__Animate-sc'] {
      @keyframes slideFromTop {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
      @keyframes slideToTop {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

      [class*='SideNavigationstyle__SideNavigation-sc'] {
        [class*='SideNavigationstyle__Container-sc'] {
          margin: 0;
          max-width: unset;

          [class*='SimpleGridstyle__SimpleGrid-sc'] {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            [class*='SimpleGridstyle__SimpleGridColumn-sc'] {
              height: unset;
              width: 100%;

              [class*='SideNavigationstyle__PrimaryNavContainer-sc'] {
                background-color: #ffffffe6;
                padding: 0;

                [class*='SideNavigationstyle__NavContents-sc'] {
                  max-width: ${rem('1232px')};
                  width: 100%;
                  margin: 0 auto;
                  display: flex;
                  justify-content: flex-end;

                  [class*='ListGroupstyle__ListGroup-sc'] {
                    width: calc(((100% / 12) * 11) - 0px);
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    [class*='ListItemstyle__ListItem-sc'] {
                      margin: 0 ${rem('20px')};
                    }
                  }
                }
              }
            }
          }

          [class*='SideNavigationstyle__CloseButton-sc'] {
            display: none;
          }
        }

        &[class*='animation-container-enter'] {
          transition-timing-function: cubic-bezier(0, 1, 1, 1);
          animation: slideFromTop 0.2s 0s;
        }

        &[class*='animation-container-exit'] {
          transition-timing-function: cubic-bezier(1, 0, 1, 0.5);
          animation: slideToTop 1s 0s;
        }
      }
    }
  }

  [class*='SimpleGridstyle__SimpleGrid'] {
    [class*='SimpleGridstyle__SimpleGridColumn'] {
      [class*='IconNavstyle__IconLinkWrapper-sc'] {
        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
          padding: 0 ${rem('12px')};
        }
      }
    }
  }

  [class*='PrimaryNavstyle__PrimaryNav'] {
    line-height: ${rem('0px')};
    [class*='PrimaryNavstyle__PrimaryNavLink'] {
      font-size: ${rem('24px')};
      font-family: 'VodafoneLight';
      line-height: ${rem('28px')};
      padding: ${rem('26px')} 0;
      display: block;
      position: relative;
      &:hover {
        color: var(--red);
        ::after {
          content: '';
          position: absolute;
          background-color: var(--red);
          height: ${rem('3px')};
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
        display: none;
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
          theme.breakpoints.lg - 1}px) and (-webkit-min-device-pixel-ratio: 1) {
        display: none;
      }
    }
  }

  [class*='SideNavigationstyle__SideNavigation'] {
    [class*='SideNavigationListstyle__LinkListItem'] {
      &:hover {
        background-color: #fff;
        color: #e60000;
      }
      padding: ${rem('15px')} ${rem('20px')};
      margin: 0 !important;
      [class*='SideNavigationListstyle__MenuLinkText'] {
        font-size: ${rem('24px')};
        line-height: ${rem('28px')};
        font-weight: initial;
        font-family: 'VodafoneLight';
        &:hover {
          background-color: #fff;
          color: #e60000;
        }
      }
    }
  }

  [class*='SecondaryNavstyle__SecondaryNav-sc'] {
    [class*='IconNavstyle__IconLinkWrapper-sc'] {
      flex-direction: column;
      position: relative;
      margin-top: ${({ user }) => user && rem('26px')};
      justify-content: start;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
        margin-top: ${({ user }) => user && rem('12px')};
      }
    }

    p {
      font-family: 'VodafoneLight';
      font-size: ${rem('12px')};
      margin: 0;
      width: max-content;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
        font-size: ${rem('10px')};
        line-height: ${rem('10px')};
      }
    }
  }
`;
