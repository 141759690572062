import { Box } from '@chakra-ui/react';
import Button from '@vfuk/core-button';
import Divider from '@vfuk/core-divider';
import Heading from '@vfuk/core-heading';
import { InteractionProps } from '@vfuk/core-interaction/dist/Interaction.types';
import Paragraph from '@vfuk/core-paragraph';
import axios from 'axios';
import { rem } from 'polished';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import Container from '@/components/Global/Container';
import { costOneUnit } from '@/helpers/findPrices';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { useModalContext } from '@/hooks/modal/useModal';
import { productOrder, ProductOrderBasketProduct } from '@/services/product.services';
import { useAppSelector, UserBasketItem, UserBasketItemBase } from '@/store';
import { OPCOOptions } from '@/types/Services.types';

import { ReactComponent as EmptyBasketIcon } from '../../../../images/icon/empty-basket.svg';
import { Price } from './Price';
import { ProductList } from './ProductList';
import {
  BasketWrapper,
  ButtonsInnerOne,
  ButtonsWrapper,
  ButtonWrapper,
  EmptyBasketContainer,
  EmptyBasketDiv,
  EmptyBasketSubtitle,
  EmptyBasketTitle,
  TotalInner,
  TotalInnerItem,
  TotalWrapper,
  WrapHeading,
  WrapSummary,
} from './SWBasket.styles';
import { SWBasketProps } from './SWBasket.types';
import { useTranslation } from 'react-i18next';

const nullValue = 'NA';
const OPCO = process.env.GATSBY_OPCO;

const TotalInnerItemText: FC<{ children: React.ReactNode }> = ({ children }: { children: React.ReactNode }) => (
  <Box as="span" color="var(--mineShaft)" fontSize="xl" letterSpacing={{ base: 'wider', lg: 'normal' }}>
    {children}
  </Box>
);

const transformToItemOrder = (item: UserBasketItem, quantity: UserBasketItemBase['quantity']) => {
  const productUuid =
    item.__typename === 'ContentfulMarketplaceProductBundle' && item.selectedEdition
      ? item.selectedEdition
      : item?.dxlInformation?.periods[0]?.uuid;
  const unit =
    item.__typename === 'ContentfulMarketplaceProductBundle' && item.selectedEdition
      ? costOneUnit(item.dxlInformation?.periods?.find(price => price.uuid === item.selectedEdition)?.prices)
      : costOneUnit(item.dxlInformation?.price);
  return {
    productUuid,
    qty: quantity || item.quantity,
    unit,
  };
};

const SWBasket: FC<SWBasketProps> = ({
  id,
  contentful_id,
  name,
  summaryTitle,
  continueShoppingButton,
  requestCallbackButton,
  goToCheckoutButton,
}) => {
  const { t } = useTranslation();
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const { user } = useAppSelector(({ userSession }) => userSession);
  const { width } = useWindowSize();
  const { toggleModal, setErrorInfo } = useModalContext();

  const postProductOrder: InteractionProps['onInteraction'] = async e => {
    e.preventDefault();
    toggleModal(true, 'LoadingCheckout');

    if (
      user?.organizationId &&
      user?.userId &&
      userBasket &&
      userBasket.items.length > 0 &&
      !userBasket.items.find(item => !item.dxlInformation.periods[0].uuid)
    ) {
      const products = userBasket.items.flatMap(item => {
        const { quantity } = item;
        if (
          item.__typename === 'ContentfulMarketplaceProductBundle' &&
          item.productsFreeOfCharge &&
          item.productsFreeOfCharge.length > 0
        ) {
          return [
            transformToItemOrder(item, undefined),
            ...item.productsFreeOfCharge.map(subItem => transformToItemOrder(subItem, quantity)),
          ];
        } else if (item.__typename === 'ContentfulMarketplaceProductBundle' && !item.productsFreeOfCharge) {
          return transformToItemOrder(item, undefined);
        }
        return transformToItemOrder(item, undefined);
      }) as ProductOrderBasketProduct[];

      const res = await productOrder(
        {
          basket: {
            organizationId: user?.organizationId,
            userId: user?.userId,
            // INFO: The order sent to the marketplace need to be checked if it's being calculated right, because here
            // we are just sending the prices to cost one, maybe we should send every unit price related to the product.
            products,
          },
        },
        user.csrfToken || ''
      );

      if (!axios.isAxiosError(res)) {
        window.location.href = `${process.env.GATSBY_APPDIRECT_BASE_URL}/partner/login/${process.env.GATSBY_APPDIRECT_TEMP_CONFIG_ID}?login_hint=OPCO:${process.env.GATSBY_OPCO},USERNAME:${user.username}&stateToken=/checkout/cart&acr_values=${process.env.GATSBY_ACR_VALUE_CHECKOUT}`;
      } else {
        toggleModal(false, 'LoadingCheckout');
        setErrorInfo({
          ...(axios.isAxiosError(res) && {
            statusType: res.response?.data?.error,
            statusCode: res.response?.data?.code || res.response?.status,
          }),
          requestUrl: 'product-order',
        });
        toggleModal(true, 'Error');
      }
    }
  };
  const montlyCostValue = checkForZeroOrNull(userBasket.total?.monthly?.gross, nullValue);

  return (
    <BasketWrapper key={id.concat('-', contentful_id)} direction="column" align="center">
      <Container
        backgroundColor="primary2.default"
        pt={width <= 768 ? rem('32px') : rem('52px')}
        pb={width <= 768 ? rem('32px') : rem('44px')}
      >
        <WrapHeading>
          <Heading level={2} justify={'center'} text={name} />
          <Paragraph size={2}>
            <span>Account</span>
            <br />
            <b>{user?.selectedAccount?.customerAccountId}</b>
          </Paragraph>
        </WrapHeading>
      </Container>

      <Container backgroundColor="#f4f4f4" pb="4.375rem">
        <WrapSummary direction="column" align={{ base: 'center', md: 'flex-start' }} py={8}>
          {userBasket.items && userBasket.items.length > 0 ? (
            <>
              <Heading level={2} weight={3} text={summaryTitle} />
              <ProductList products={userBasket.items} />
              <Box
                my={{ base: 6, lg: 7 }}
                w="full"
                sx={{
                  '> *': {
                    borderTop: '2px solid',
                    borderColor: 'mineShaft',
                  },
                }}
              >
                <Divider appearance="alt3" level={1} noMargin />
              </Box>

              <TotalWrapper>
                <Box as="span" fontSize="xl" fontWeight={{ base: 'bolder' }} letterSpacing="wider">
                  {t('totalCost')}
                </Box>
                <TotalInner>
                  <Box mx={{ base: 5, lg: 10 }} textAlign={{ lg: 'center' }} alignSelf="end">
                    <Divider appearance="alt3" level={2} orientation="vertical" noMargin />
                  </Box>

                  <TotalInnerItem>
                    <TotalInnerItemText>{t('monthly')}</TotalInnerItemText>
                    <Price
                      isTotalSection
                      value={montlyCostValue}
                      priceFontSize={montlyCostValue === nullValue ? rem('26px') : null}
                    />
                  </TotalInnerItem>
                </TotalInner>
              </TotalWrapper>
            </>
          ) : (
            <EmptyBasketContainer>
              <EmptyBasketDiv>
                <EmptyBasketIcon />
              </EmptyBasketDiv>
              <EmptyBasketDiv>
                <EmptyBasketTitle>Your basket is currently empty!</EmptyBasketTitle>
                <EmptyBasketSubtitle>
                  Before proceeding to checkout you must add some products to your shopping cart.
                </EmptyBasketSubtitle>
              </EmptyBasketDiv>
            </EmptyBasketContainer>
          )}

          <ButtonsWrapper
            direction={{ base: 'column', md: 'row' }}
            justify={{ md: 'space-between' }}
            spacing={{ base: 8, md: 0 }}
            mt={{ base: 10, lg: 14 }}
            w="full"
            data-selector="basket buttons"
          >
            <ButtonsInnerOne>
              {OPCO !== OPCOOptions.PORTUGAL && (
                <ButtonWrapper appearance="alt1">
                  <Button text={continueShoppingButton.label} appearance="alt1" href={continueShoppingButton.url} />
                </ButtonWrapper>
              )}
              <ButtonWrapper appearance="alt1">
                <Button text={requestCallbackButton.label} appearance="alt1" href={requestCallbackButton.url} />
              </ButtonWrapper>
            </ButtonsInnerOne>

            <ButtonWrapper appearance={goToCheckoutButton.rank}>
              <Button
                state={userBasket && userBasket.items?.length > 0 ? undefined : 'disabled'}
                text={goToCheckoutButton.label}
                appearance={goToCheckoutButton.rank}
                onClick={postProductOrder}
              />
            </ButtonWrapper>
          </ButtonsWrapper>
        </WrapSummary>
      </Container>
    </BasketWrapper>
  );
};

export default SWBasket;
