import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '@vfuk/core-button';
import Image from '@vfuk/core-image';
import LinkCard from '@vfuk/core-link-card';
import SimpleGrid from '@vfuk/core-simple-grid';
import Spacing from '@vfuk/core-spacing';
import axios from 'axios';
import { withPrefix } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';

import { lowestEditionPrice } from '@/helpers/findPrices';
import formatCurrency from '@/helpers/formatCurrency';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';

import PriceTag from '../PriceTag/PriceTag';
import { ShortenedHeader } from './ShortenedHeader';
import {
  FeatureCardImagesAndPriceWrapper,
  FeatureCardProductImageWrapper,
  FeatureCardWrapper,
} from './SWFeatureCard.styles';
import { SWFeatureCardProps } from './SWFeatureCard.types';

const SWFeatureCard = ({
  backgroundImage,
  currencySymbol = '€',
  heading,
  pillText,
  richTextBody,
  isPropositionCard,
  primaryButton,
  primaryImage,
  productIcon,
  productId,
}: SWFeatureCardProps) => {
  const productIconSrc = getSrc(productIcon) || getImage(productIcon)?.placeholder?.fallback || '';
  const primaryImageSrc = getSrc(primaryImage) || getImage(primaryImage)?.placeholder?.fallback || '';
  const backgroundImageSrc = getSrc(backgroundImage) || getImage(backgroundImage)?.placeholder?.fallback || '';
  const [price, setPrice] = useState<string>('XX');
  const { setErrorInfo, toggleModal } = useModalContext();

  const formattedHeading =
    heading.length > 59 ? <ShortenedHeader heading={heading} maxSize={59} hoverText={'...'} /> : heading;

  useEffect(() => {
    (async () => {
      try {
        if (productId && productId.match('[0-9]{5,6}')) {
          const res = await retrieveProduct(productId);
          if (!axios.isAxiosError(res) && res.status === 200) {
            const productPrice = formatCurrency(Number(lowestEditionPrice(res.data.dxlInformation)));
            setPrice(productPrice || '0.00');
          } else {
            setErrorInfo({
              ...(axios.isAxiosError(res) && {
                statusType: res.response?.data?.error,
                statusCode: res.response?.data?.code || res.response?.status,
              }),
              requestUrl: 'catalogues',
            });
            toggleModal(true, 'Error');
          }
        }
      } catch (err) {
        const error = err as TError;
        isEnvDevelopment() && console.log(error);
      }
    })();
  }, [productId]);

  return (
    <FeatureCardWrapper
      className={isPropositionCard ? 'propositionCard' : ''}
      pill={pillText}
      data-selector="feature card"
    >
      {!isPropositionCard && (
        <FeatureCardImagesAndPriceWrapper>
          <SimpleGrid
            layout={{
              sm: [12, 7, 5],
              md: [12, 7, 5],
              lg: [2, 7, 3],
              xl: [2, 7, 3],
            }}
            spacing={0}
          >
            <Spacing
              spacingLevel={{
                top: 6,
                left: 6,
                bottom: 6,
              }}
            >
              <Image
                alt={`${heading} product icon`}
                sm={{
                  src: productIconSrc,
                  width: '50px',
                  height: 'auto',
                }}
              />
            </Spacing>
            <Spacing
              spacingLevel={{
                left: 6,
              }}
            >
              <FeatureCardProductImageWrapper>
                <Image
                  alt={`${heading} product image`}
                  sm={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                      x3: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '140px',
                  }}
                  md={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                      x3: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '140px',
                  }}
                  lg={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '200px',
                  }}
                />
              </FeatureCardProductImageWrapper>
            </Spacing>
            <PriceTag {...{ price, currencySymbol }} />
          </SimpleGrid>
        </FeatureCardImagesAndPriceWrapper>
      )}
      {isPropositionCard ? (
        <LinkCard
          elevation
          appearance="primary"
          image={{
            xl: {
              src: backgroundImageSrc,
            },
            alt: '',
          }}
          headingText={formattedHeading}
          text={documentToReactComponents(JSON.parse(richTextBody?.raw))}
        />
      ) : (
        <>
          <LinkCard
            elevation
            appearance="primary"
            image={{
              xl: {
                src: backgroundImageSrc,
              },
              alt: '',
            }}
            headingText={formattedHeading}
            text={documentToReactComponents(JSON.parse(richTextBody?.raw))}
            {...(pillText && {
              pill: {
                text: pillText,
              },
            })}
          />
          {primaryButton && (
            <Button
              text={primaryButton.label}
              appearance="primary"
              href={withPrefix(primaryButton.url)}
              dataSelectorPrefix={`${heading.toLowerCase()}`}
            />
          )}
        </>
      )}
    </FeatureCardWrapper>
  );
};

export default SWFeatureCard;
