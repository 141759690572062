import { rem } from 'polished';
import styled from 'styled-components';

export const StandardBannerWrapper = styled.div`
  [class*='StandardBannerstyle__FullWidthContainer-sc'] {
    background: rgba(0, 0, 0, 0);
    max-width: ${rem('1180px')};
    margin: 0 auto;
    display: block;
    padding: 0;
    height: ${rem('500px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      height: ${rem('570px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      position: inherit;
    }

    [class*='StandardBannerstyle__StandardBanner-sc'] {
      [class*='SimpleGridstyle__SimpleGrid-sc'] {
        margin: 0;

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          flex-direction: column;
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          flex-direction: row;
        }

        [class*='SimpleGridstyle__SimpleGridColumn-sc'] {
          margin: 0;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
              theme.breakpoints.lg}px) {
            width: 50%;
          }

          > [class*='SimpleGridstyle__SimpleGrid-sc'] {
            display: block;

            [class*='ContentWrapperstyle__ContentWrapper-sc'] {
              align-items: flex-start;
              width: ${rem('685px')};
              padding: ${rem('30px')} 0 0 ${rem('8px')};
              position: relative;
              z-index: 1;

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                max-width: ${rem('320px')};
                padding: ${rem('32px')} 0 0 ${rem('15px')};
                height: fit-content;
              }

              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                  theme,
                }) => theme.breakpoints.lg}px) {
                width: ${rem('400px')} !important;
                padding-left: ${rem('16px')} !important;
                margin-top: 25%;
              }

              h1 {
                color: var(--white);
                padding: 0;
                text-align: left;
                font-weight: 400;
                font-size: ${rem('64px')};
                line-height: ${rem('40px')};
                margin-bottom: ${rem('12px')};

                @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                  font-size: ${rem('32px')};
                  margin-bottom: ${rem('3px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                    theme,
                  }) => theme.breakpoints.lg - 1}px) {
                  width: auto;
                  font-size: ${rem('48px')};
                  line-height: ${rem('55px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                  line-height: ${rem('65px')};
                  margin-bottom: ${rem('20px')};
                }
              }

              p {
                font-size: ${rem('24px')};
                line-height: ${rem('29px')};
                color: #f4f4f4;
                padding: 0;
                text-align: left;

                @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                  width: auto;
                  margin-top: ${rem('-16px')};
                  font-size: ${rem('20px')};
                  line-height: ${rem('28px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                  width: 75%;
                  font-size: ${rem('18px')};
                  line-height: ${rem('18px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                  font-size: ${rem('24px')};
                  line-height: ${rem('29px')};
                }
              }
            }
          }
        }

        [class*='ImageContainerstyle__BackgroundWrapper-sc'] {
          position: absolute;
          left: 0;

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
            height: ${rem('570px')};
            top: 0;
          }

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
            height: ${rem('500px')};
          }

          [class*='ImageContainerstyle__BackgroundImage-sc'] {
            @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
              background-position: 50% 58%;
              background-size: 100%;
              height: -webkit-fill-available;
              position: relative;
              top: 0;
            }

            @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
              background-position: 50% 20%;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  [class*='redCircularGradient'] {
    background: radial-gradient(circle, rgba(130, 0, 0, 1) 0%, rgba(130, 0, 0, 1) 17%, rgba(166, 0, 0, 1) 100%);

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      [class*='ContentWrapperstyle__ContentWrapper-sc'] {
        margin-top: 50% !important;
      }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg - 1}px) {
      [class*='ContentWrapperstyle__ContentWrapper-sc'] {
        width: ${rem('400px')} !important;
        padding-left: ${rem('16px')} !important;
      }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      [class*='ContentWrapperstyle__ContentWrapper-sc'] {
        height: auto;
      }
    }

    [class*='ImageContainerstyle__BackgroundWrapper-sc'] {
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        height: -webkit-fill-available;
        bottom: ${rem('40px')};

        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 50% 83% !important;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
          theme.breakpoints.lg - 1}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: right 50% !important;
          background-size: 54%;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) and (max-width: ${({ theme }) =>
          theme.breakpoints.xl - 1}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 100% 50% !important;
          background-size: 50%;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 75% 50% !important;
          background-size: contain !important;
        }
      }
    }
  }
`;
