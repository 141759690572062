import { Box, Button, Flex, forwardRef, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Icon from '@vfuk/core-icon';
import { rem } from 'polished';

import { ExtraInformationProps } from './SWPricingCard.types';

const ExtraInformation = forwardRef<ExtraInformationProps, 'div'>(({ extraInformation, ...props }, ref) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      ref={ref}
      bg={isOpen ? '#666666' : '#f2f2f2'}
      flexDir="column"
      alignItems="center"
      justifyContent="space-between"
      h="full"
      {...props}
    >
      <Text
        as={Box}
        {...(isOpen && { display: 'none' })}
        color="mineShaft"
        fontSize={rem('14px')}
        lineHeight={{ base: rem('17px'), md: 6 }}
        my={0}
        px={5}
        width={'full'}
      >
        {extraInformation[0].textOne && documentToReactComponents(JSON.parse(extraInformation[0].textOne.raw))}
      </Text>
      <Text
        as={Box}
        {...(!isOpen && { display: 'none' })}
        color="white"
        fontSize={rem('14px')}
        lineHeight={{ base: rem('17px'), md: 6 }}
        my={0}
        px={5}
        width={'full'}
      >
        {extraInformation[0].textTwo && documentToReactComponents(JSON.parse(extraInformation[0].textTwo.raw))}
      </Text>
      {extraInformation[0].textTwo && (
        <Stack flexDir={isOpen ? 'row-reverse' : 'row'}>
          <Button
            onClick={onToggle}
            color={isOpen ? 'white' : 'black'}
            background="transparent"
            width="fit-content"
            _hover={{ background: 'transparent' }}
            _focus={{ outline: 'none', background: 'transparent' }}
            textDecoration="underline"
            borderRadius="none"
            fontWeight="hairline"
            lineHeight={rem('19px')}
            mb="4"
          >
            {`Show ${isOpen ? 'less' : 'more'}`}
          </Button>
          <Box ml={'11px'} transform={isOpen ? 'rotateY(180deg)' : 'none'} transition="0.2s ease-out">
            <Icon appearance="secondary" group="system" name={'chevron-right'} />
          </Box>
        </Stack>
      )}
    </Flex>
  );
});

export default ExtraInformation;
