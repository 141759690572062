import { rem } from 'polished';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '@vfuk/core-button';
import Heading from '@vfuk/core-heading';
import { Icon } from '@source-web/icon';
import { useModalContext } from '@/hooks/modal/useModal';

import Form from '../../Elements/Form';
import { SWFormModalProps } from './SWFormModal.types';
import {
  FormContainer,
  SWFormButton,
  SWFormModalCloseBtn,
  SWFormModalHeader,
  SWFormModalHeaderIcon,
  SWFormModalWrapper,
} from './SWFormModal.styles';

const SWFormModal = ({ button, buttonIconName, form }: SWFormModalProps) => {
  const { isModalOpen, toggleModal } = useModalContext();

  const openModal = () => {
    toggleModal(true);
  };

  return (
    form && (
      <>
        <SWFormButton>
          <Button
            text={button}
            icon={buttonIconName ? { name: buttonIconName, justify: 'left' } : undefined}
            onClick={openModal}
          />
        </SWFormButton>

        <Modal
          isCentered
          size="4xl"
          isOpen={isModalOpen}
          onClose={() => {
            toggleModal(false);
          }}
          closeOnOverlayClick={false}
          data-testid="swFormModal"
        >
          <ModalOverlay bgColor="#212121" />
          <ModalContent
            borderRadius={rem('6px')}
            boxShadow="none"
            margin={rem('16px')}
            maxHeight={`calc(100vh - ${rem('40px')})`}
          >
            <SWFormModalHeader>
              <SWFormModalHeaderIcon>
                <Icon name="consultant" group="system" inverse size={5} />
              </SWFormModalHeaderIcon>
              <Heading level={3} justify="center">
                {form.title}
              </Heading>
              <SWFormModalCloseBtn>
                <ModalCloseButton
                  color="white"
                  sx={{
                    svg: {
                      boxSize: 4,
                    },
                  }}
                />
              </SWFormModalCloseBtn>
            </SWFormModalHeader>
            <SWFormModalWrapper>
              {form?.subtitle && (
                <Heading level={4} justify="center">
                  {form.subtitle}
                </Heading>
              )}
              <FormContainer>
                <Form formInputs={form.formInputs} />
              </FormContainer>
              <Button
                text={form.submitButton.label}
                appearance="primary"
                href={form.submitButton.url}
                dataSelectorPrefix={`submitFormButton`}
              />
            </SWFormModalWrapper>
          </ModalContent>
        </Modal>
      </>
    )
  );
};

export default SWFormModal;
