import Button from '@vfuk/core-button';
import CardSandwichSlot from '@vfuk/core-card-sandwich-slot';
import ContainerWithLabel from '@vfuk/core-container-with-label';
import Image from '@vfuk/core-image';
import LinkWithIcon from '@vfuk/core-link-with-icon';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import axios from 'axios';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { lowestEditionPrice } from '@/helpers/findPrices';
import formatCurrency from '@/helpers/formatCurrency';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { retrieveProduct } from '@/services/product.services';
import { Product } from '@/types/Product.types';

import { ShortenedHeader } from '../SWFeatureCard/ShortenedHeader';
import { Content, ContentText, ImgWrapper, InfoAndCta, LoadingWrapper, Wrapper } from './SWProductStrip.styles';
import { SWProductStripProps } from './SWProductStrip.types';

const SWProductStrip = ({ id, productId, name, thumbnailIcon, description, slug, ...props }: SWProductStripProps) => {
  const isBundle = props.__typename === 'ContentfulMarketplaceProductBundle' && props.isBundle;
  const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';
  const [productData, setProductData] = useState<Product['dxlInformation']>();
  const price = lowestEditionPrice(productData);
  const { width } = useWindowSize();
  const { setErrorInfo, toggleModal } = useModalContext();

  const formattedHeading =
    (productData?.name || '').length > 35 && width <= 400 ? (
      <ShortenedHeader heading={productData?.name || ''} maxSize={35} hoverText={'...'} />
    ) : (
      productData?.name
    );

  useEffect(() => {
    (async () => {
      if (productId) {
        const res = await retrieveProduct(productId);
        if (!axios.isAxiosError(res) && res.status === 200) {
          setProductData(res.data.dxlInformation);
        } else {
          setErrorInfo({
            ...(axios.isAxiosError(res) && {
              statusType: res.response?.data?.error,
              statusCode: res.response?.data?.code || res.response?.status,
            }),
            requestUrl: 'catalogues',
          });
          toggleModal(true, 'Error');
          isEnvDevelopment() && console.log({ error: res });
        }
      }
    })();
  }, [productId]);

  return (
    <Wrapper key={id} data-selector="product strip">
      <ContainerWithLabel {...(isBundle && { label: 'Bundle' })}>
        <ImgWrapper>
          <Image
            alt={thumbnailIcon?.title || ''}
            sm={{
              src: thumbnailIconSrc,
              width: 'auto',
            }}
          />
        </ImgWrapper>
        <Content>
          {productData?.name ? (
            <LinkWithIcon
              appearance="primary"
              text={formattedHeading}
              icon={{
                name: 'chevron-right',
                justify: 'right',
              }}
              href={price === 0 ? '' : slug}
              size={1}
            />
          ) : (
            <LoadingSpinner appearance="primary" size={5} />
          )}
          <ContentText>{description?.description || ''}</ContentText>
        </Content>
        <InfoAndCta>
          {productData?.name ? (
            <CardSandwichSlot
              {...{
                primaryPrice: {
                  unit: '€',
                  text: {
                    main: `${formatCurrency(price)}`,
                    mainSuffix: '/ month (ex VAT)',
                    prefix: 'Starting at',
                  },
                },
              }}
            />
          ) : (
            <LoadingWrapper>
              <LoadingSpinner appearance="primary" size={5} />
            </LoadingWrapper>
          )}
          <Button
            {...(price === 0 && { state: 'disabled' })}
            text={price === 0 ? 'Not Available' : 'View Details'}
            appearance={price === 0 ? 'secondary' : 'primary'}
            href={slug || ''}
            {...(name && {
              dataSelectorPrefix: name.toLowerCase(),
            })}
          />
        </InfoAndCta>
      </ContainerWithLabel>
    </Wrapper>
  );
};

export default SWProductStrip;
