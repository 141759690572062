import SelectInput from '@vfuk/core-select-input';
import SimpleGrid from '@vfuk/core-simple-grid';
import { navigate } from 'gatsby';
import { FC, useMemo, useState } from 'react';

import { SWSelectProps } from './SWSectionSelect.types';

const SWSectionSelect: FC<SWSelectProps> = ({ products = [], selectOptions, heading = '' }) => {
  const [category, setCategory] = useState<string>('');

  const categoryOptions = useMemo(() => {
    if (selectOptions) {
      return selectOptions.map(item => ({
        text: item.optionText,
        value: item.optionValue,
      }));
    } else {
      return null;
    }
  }, products);

  const productOptions = useMemo(() => {
    if (!products) return [];
    if (category.trim() !== 'All Categories' && category.trim() !== '') {
      return products
        .filter(item => item.category?.name.trim() === category.trim())
        .map(product => ({
          text: product.name,
          value: product.slug,
        }));
    } else {
      return products.map(product => ({
        text: product.name,
        value: product.slug,
      }));
    }
  }, [category, products]);

  return (
    <SimpleGrid
      layout={{
        sm: [12],
        md: categoryOptions ? [5, 7] : [12],
      }}
      justify="center"
      spacing={2}
    >
      {categoryOptions && (
        <SelectInput
          options={categoryOptions}
          onChange={event => setCategory(event.target.value)}
          dataSelectorPrefix={heading.toLowerCase()}
        />
      )}

      <SelectInput
        placeholder={'Select Product'}
        options={productOptions}
        onChange={event => navigate(event.target.value)}
        dataSelectorPrefix={heading.toLowerCase()}
      />
    </SimpleGrid>
  );
};

export default SWSectionSelect;
