import { AxiosError } from 'axios';

import { UserBasketItem, UserSessionProps } from '@/store';
import { DxlInformation, DxlInformationPrice, Product } from '@/types/Product.types';

import { api } from './auth.services';

type RetrieveProductProps = Pick<Product, 'id'> & Pick<Product, 'dxlInformation'>;

export type ProductOrderBasketProduct = {
  productUuid: DxlInformation['uuid'];
  qty: UserBasketItem['quantity'];
  unit: DxlInformationPrice['unit'];
};

export type ProductOrderBasket = Pick<UserSessionProps, 'organizationId' | 'userId'> & {
  products: ProductOrderBasketProduct[];
};

export type ProductOrderProps = {
  basket: ProductOrderBasket;
};

export const retrieveProduct = async (productId: string) => {
  const res = await api.get<RetrieveProductProps>(`/catalogues/ireland/${productId}`).catch<AxiosError>(error => error);
  return res;
};

export const productOrder = async (productOrder: ProductOrderProps, csrfToken: string) => {
  const res = await api
    .post('/product-order', productOrder, {
      headers: { Authorization: csrfToken },
    })
    .catch<AxiosError>(error => error);
  return res;
};
