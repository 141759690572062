import { Box, Flex, Heading, Stack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '@vfuk/core-button';
import ButtonWithIcon from '@vfuk/core-button-with-icon';
import Icon from '@vfuk/core-icon';
import { IconProps } from '@vfuk/core-icon/dist/Icon.types';
import { navigate, withPrefix } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import Container from '@/components/Global/Container';
import stringifyContent from '@/helpers/stringifyContent';
import { useModalContext } from '@/hooks/modal/useModal';

import { OptionDestinationProps } from '../SWConfigureBundleOptions/SWConfigureBundleOptions.types';
import { CancelButton, CtaButton } from './SWConfigureBusinessNeeds.styles';
import { SWConfigureBusinessNeedsProps } from './SWConfigureBusinessNeeds.types';
const SWConfigureBusinessNeeds: FC<SWConfigureBusinessNeedsProps> = ({
  id,
  contentful_id,
  sectionTitle,
  backgroundColor,
  leftButtonDestinationType,
  leftButtonDestinationLink,
  options,
}) => {
  const sectionId = `${id}-${contentful_id}`;
  const iconSize: IconProps['size'] = useBreakpointValue({ base: 3, md: 2 });
  const { height } = useWindowSize();

  const { toggleModal } = useModalContext();

  const handleButtonAction = (
    buttonDestinationType: OptionDestinationProps['destinationType'],
    configurationButtonDestinationLink: OptionDestinationProps['destinationLink'],
    buttonDestinationLink: OptionDestinationProps['destinationLink']
  ) => {
    if (buttonDestinationType === 'internal' && configurationButtonDestinationLink) {
      navigate(withPrefix(`${configurationButtonDestinationLink}`));
    } else if (buttonDestinationType === 'external' && configurationButtonDestinationLink) {
      navigate(`${configurationButtonDestinationLink}`);
    } else if (buttonDestinationType === 'internal' && buttonDestinationLink) {
      navigate(withPrefix(`${buttonDestinationLink}`));
    } else if (buttonDestinationType === 'external' && buttonDestinationLink) {
      navigate(`${buttonDestinationLink}`);
    } else {
      toggleModal(true, 'MsDomain');
    }
  };

  return (
    <Box
      as="section"
      id={sectionId}
      backgroundColor={backgroundColor}
      py={{ base: rem('17px'), md: rem('116px'), lg: rem('152px') }}
      h="full"
      minH={height}
    >
      <Container pb={{ base: rem('82px'), md: 0 }}>
        <Flex direction={{ base: 'column', md: 'row' }} textAlign="center">
          <CancelButton>
            <ButtonWithIcon
              icon={{
                name: 'chevron-left',
                justify: 'left',
              }}
              inverse
              text="Back"
              {...(!leftButtonDestinationLink && {
                state: 'disabled',
              })}
              href={
                leftButtonDestinationType === 'internal' && leftButtonDestinationLink
                  ? withPrefix(leftButtonDestinationLink)
                  : leftButtonDestinationLink
              }
              appearance="alt1"
            />
          </CancelButton>

          <Flex
            direction="column"
            justify="center"
            mt={{ base: rem('24px'), md: 0 }}
            px={{ md: rem('68px') }}
            textAlign="center"
            w="full"
            sx={{
              'h2, h3': {
                color: 'white',
              },
              h2: {
                fontSize: { base: '4xl', md: 10 },
                lineHeight: { base: rem('48px'), md: rem('46px'), lg: rem('48px') },
                mb: 0,
                whiteSpace: { base: 'normal', lg: 'pre-wrap' },
              },
              h3: {
                fontSize: { base: '2xl', md: rem('29px') },
                letterSpacing: { base: 0, md: 'tight' },
                lineHeight: rem('48px'),
                mb: 0,
                mt: { base: 5, md: rem('38px') },
              },
              p: {
                m: { base: `0 0 ${rem('12px')}`, md: `0 0 ${rem('25px')}` },
                '&:last-child': {
                  m: 0,
                },
              },
              'b, strong': {
                letterSpacing: 'wide',
              },
            }}
          >
            {documentToReactComponents(JSON.parse(sectionTitle.raw))}
          </Flex>

          <CancelButton visibility="hidden" display={{ base: 'none', md: 'flex' }}>
            <ButtonWithIcon
              icon={{
                name: 'chevron-left',
                justify: 'right',
              }}
              text="Next"
              appearance="alt1"
            />
          </CancelButton>
        </Flex>

        <Flex align="center" direction="column" mt={{ base: rem('90px'), md: rem('77px'), lg: rem('98px') }}>
          <VStack maxW={rem('780px')}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 5 }}>
              {options.map(
                ({
                  id,
                  contentful_id,
                  optionHeader,
                  optionBody,
                  optionTextColor,
                  backgroundColor,
                  backgroundImage,
                  hasBackgroundIcon = false,
                  iconName,
                  buttonTitle,
                  buttonAppearance,
                  buttonDestinationType,
                  buttonDestinationLink,
                  configurationButtonDestinationLink,
                }) => (
                  <Flex
                    key={`${id}${contentful_id}`}
                    align="flex-start"
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage ? `url(${getSrc(backgroundImage)})` : 'none'}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    boxShadow={`0 ${rem('18px')} ${rem('43px')} 0 rgba(0, 0, 0, 0.52)`}
                    direction="column"
                    p={5}
                    w={{ base: rem('345px'), md: rem('244px'), lg: rem('260px') }}
                    h={rem('324px')}
                    borderRadius={'6px'}
                  >
                    <Box
                      height={rem('48px')}
                      mb={{ base: rem('50px'), md: 'auto' }}
                      mt={{ base: rem('6px') }}
                      display={'flex'}
                    >
                      {iconName && <Icon group="midLight" name={iconName} size={iconSize} />}
                      <Heading
                        as="h3"
                        color={optionTextColor}
                        fontSize={{ base: 'xl' }}
                        lineHeight={rem('22px')}
                        mb={0}
                        ml={(iconName && { base: rem('10px') }) || (hasBackgroundIcon && { base: rem('58px') }) || 0}
                      >
                        {optionHeader}
                      </Heading>
                    </Box>
                    <Heading
                      as="h3"
                      color={optionTextColor}
                      fontSize={{ base: 'xl', md: 'lg' }}
                      fontWeight="normal"
                      lineHeight={rem('22px')}
                      mb={0}
                      sx={{ p: { m: 0 } }}
                      display={'flex'}
                      flexDirection={'column'}
                      flexBasis={'50%'}
                    >
                      {documentToReactComponents(JSON.parse(optionBody.raw))}
                    </Heading>

                    <CtaButton>
                      <Button
                        width="full"
                        appearance={buttonAppearance || 'primary'}
                        inverse={buttonAppearance === 'secondary' ? true : false}
                        htmlAttributes={{
                          type: 'button',
                        }}
                        text={buttonTitle}
                        onClick={() =>
                          handleButtonAction(
                            buttonDestinationType,
                            configurationButtonDestinationLink,
                            buttonDestinationLink
                          )
                        }
                        dataSelectorPrefix={stringifyContent(JSON.parse(optionBody.raw)).toLowerCase()}
                      />
                    </CtaButton>
                  </Flex>
                )
              )}
            </Stack>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default SWConfigureBusinessNeeds;
