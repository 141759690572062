import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import Button from '@vfuk/core-button';
import Image from '@vfuk/core-image';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import SelectInput from '@vfuk/core-select-input';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { startCase } from 'lodash';
import { rem } from 'polished';
import { FC, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { DxlInformationPeriod } from '@/types/Product.types';

import { DetailsAndPricing } from '../../DetailsAndPricing';
import { InfoAndCta } from '../../InfoAndCta';
import { CardHeading, CTAWrapper, ImageWrap, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps, DetailsAndPricingProps } from '../../SWPricingCard.types';
import { ExpandableWrapper, FCWrapper, SelectWrapper } from './SWExpandingCard styles';

const SWExpandingCard: FC<CardTypeProps> = ({
  item,
  mainItem,
  selectedItem,
  itemStepName,
  shouldChangeTheme,
  mergedProduct,
}) => {
  const { width } = useWindowSize();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  const { description, thumbnailIcon, detailAndPricing, dxlInformation } = selectedItem;
  const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';

  const handleIsExpanded = () => setIsExpanded(!isExpanded);

  const options = item?.dxlInformation?.periods?.map((period: DxlInformationPeriod) => ({
    text: startCase(`${period.type} contract`.toLowerCase()),
    value: period.uuid,
  }));

  return (
    <>
      {dxlInformation ? (
        <CardHeading pb={rem('14px')} pt={{ base: rem('27px'), md: rem('21px') }} px={5}>
          <Stack direction="row" spacing={5}>
            {thumbnailIconSrc && (
              <ImageWrap>
                <Image
                  alt={thumbnailIcon?.title || ''}
                  sm={{
                    src: thumbnailIconSrc,
                    width: '48px',
                    height: '48px',
                  }}
                  md={{
                    src: thumbnailIconSrc,
                    width: '64px',
                    height: '64px',
                  }}
                />
              </ImageWrap>
            )}
            <Box minH={rem('90px')}>
              <Heading
                as="h2"
                color={shouldChangeTheme ? 'white' : 'mineShaft'}
                fontSize={width >= 768 ? rem('28px') : rem('24px')}
                fontWeight="bold"
                lineHeight={{ base: rem('30px'), md: rem('36px') }}
                mb={0}
              >
                {dxlInformation?.name}
              </Heading>
            </Box>
          </Stack>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}

      {dxlInformation && detailAndPricing && (
        <DetailsAndPricing
          itemStepName={itemStepName ? itemStepName : ''}
          item={selectedItem}
          color={shouldChangeTheme ? 'white' : undefined}
          useSimplePrice={mergedProduct}
          {...{ selectedEdition, setSelectedEdition }}
        />
      )}
      <SelectWrapper>
        {!!selectedEdition && dxlInformation?.periods && dxlInformation?.periods.length > 0 && (
          <SelectInput options={options ? options : []} onChange={event => setSelectedEdition(event.target.value)} />
        )}
      </SelectWrapper>
      <FCWrapper isExpanded={isExpanded}>
        <ExpandableWrapper isExpanded={isExpanded}>
          <Text
            color={'black'}
            fontSize={{ base: 'md', md: 'lg' }}
            lineHeight={rem('18px')}
            marginRight={5}
            marginLeft={5}
            mt={{ base: 5, md: 0 }}
          >
            {description?.description}
          </Text>
          <Button text={`Show ${isExpanded ? 'Less' : 'More'}`} onClick={handleIsExpanded} id={'ExpandButton'} />
        </ExpandableWrapper>
      </FCWrapper>
      <CTAWrapper>
        {item?.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWExpandingCard;
