import { Box, Skeleton, Stack, useBoolean } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useLocation } from '@reach/router';
import ContainerWithLabel from '@vfuk/core-container-with-label';
import { rem } from 'polished';
import { FC, useEffect, useState } from 'react';

import SWExpandingCard from './CardTypes/SWExpandingCard';
import SWFlippingCard from './CardTypes/SWFlippingCard';
import SWIncludesCard from './CardTypes/SWIncludesCard';
import SWStandardCard from './CardTypes/SWStandardCard';
import { PricingCardWrapper } from './SWPricingCard.styles';
import { DetailsAndPricingProps, SWPricingCardProps } from './SWPricingCard.types';

const SWPricingCard: FC<SWPricingCardProps> = ({
  item,
  isLoading,
  itemStepName,
  isMiddleOne,
  mergedProduct,
  includesCards,
}) => {
  const [toggleStatus, setToggleStatus] = useBoolean(false);
  const [selectedItem, setSelectedItem] = useState(item);

  const { pathname } = useLocation();
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  useEffect(() => {
    if (toggleStatus) {
      //Change edition Toggled On
      if (item) setSelectedItem({ ...item, ...item.toggleVersion });
    } else {
      //Change edition Toggled Off
      setSelectedItem(item);
    }
  }, [toggleStatus]);

  useEffect(() => {
    setSelectedItem(item);
  }, [item]);

  const renderItem = () => {
    if (!item || !selectedItem) {
      return <Skeleton isLoaded={!isLoading} height={rem('552px')} />;
    } else {
      const {
        id,
        detailAndPricing,
        enableToggleOption,
        toggleDescription,
        isFlippingCard,
        isExpandingCard,
        bundleIncludes,
        thumbnailIcon,
        extraInformation,
        customiseBundleText,
      } = selectedItem;
      const shouldChangeTheme = isMiddleOne && bundleIncludes ? bundleIncludes?.length > 0 : false;

      const containerLabelRaw = detailAndPricing?.containerLabel?.raw || '';
      const containerLabel = containerLabelRaw ? JSON.parse(containerLabelRaw) : null;

  


      return (
        <>
          <PricingCardWrapper
            key={id}
            containerAppearance={detailAndPricing?.containerAppearance}
            isMiddleOne={shouldChangeTheme}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore: Component ContainerWithLabel don't accept receiving a ReactNode at the label prop */}
            <ContainerWithLabel
              {...(detailAndPricing?.containerAppearance &&
              detailAndPricing?.containerAppearance !== 'none' &&
              detailAndPricing?.containerLabel &&
              !pathname.includes('product-list')
                ? {
                    label:
                      detailAndPricing?.containerLabel?.raw &&
                      documentToReactComponents(JSON.parse(detailAndPricing.containerLabel.raw)),
                    appearance: detailAndPricing.containerAppearance,
                  }
                : {
                    showOuterTopPadding: true,
                  })}

              {...(detailAndPricing?.containerLabel?.raw && detailAndPricing?.containerAppearance !== 'none'

                ? {
                    level: 1,
                    label: {
                      text: containerLabel?.content[0].content[0].value,
                    },
                  }
                : {
                    showOuterTopPadding: true,
                  })}
            >
              {isFlippingCard && !isExpandingCard && (
                <SWFlippingCard
                  {...{
                    item,
                    selectedItem,
                    selectedEdition,
                    itemStepName,
                    shouldChangeTheme,
                    mergedProduct,
                    thumbnailIcon,
                  }}
                />
              )}
              {isExpandingCard && !isFlippingCard && (
                <SWExpandingCard
                  {...{ item, selectedItem, selectedEdition, itemStepName, shouldChangeTheme, mergedProduct }}
                />
              )}
              {!isFlippingCard && !isExpandingCard && includesCards && includesCards.length > 0 && (
                <SWIncludesCard
                  {...{
                    item,
                    selectedItem,
                    selectedEdition,
                    itemStepName,
                    shouldChangeTheme,
                    mergedProduct,
                    includesCards,
                  }}
                />
              )}
              {!isFlippingCard && !isExpandingCard && !includesCards && (
                <SWStandardCard
                  {...{
                    item,
                    selectedItem,
                    setToggleStatus,
                    toggleStatus,
                    selectedEdition,
                    itemStepName,
                    shouldChangeTheme,
                    mergedProduct,
                    enableToggleOption,
                    pathname,
                    extraInformation,
                    toggleDescription,
                  }}
                />
              )}
            </ContainerWithLabel>
          </PricingCardWrapper>

          {customiseBundleText && !pathname.includes('product-list') && (
            <Box
              textAlign={pathname.includes('configuration') ? 'center' : 'left'}
              sx={{
                p: {
                  fontSize: 'sm',
                  m: 0,
                },
              }}
            >
              {documentToReactComponents(JSON.parse(customiseBundleText.raw))}
            </Box>
          )}
        </>
      );
    }
  };

  return (
    <Stack
      maxW={{ base: rem('345px'), md: rem('382px') }}
      w={!selectedItem ? 'full' : 'auto'}
      h={selectedItem && selectedItem.notificationStatus ? 'auto' : 'full'}
    >
      {renderItem()}
    </Stack>
  );
};

export default SWPricingCard;
