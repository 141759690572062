import { Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { BasketItem } from './BasketItem';
import { ProductListProps } from './SWBasket.types';

export const ProductList: FC<ProductListProps> = ({ products }) => (
  <Stack direction="column" alignItems="center" spacing={4} w="full">
    {products &&
      products.map((item, key) => {
        if (item.__typename === 'ContentfulMarketplaceProductBundle' && item.productsFreeOfCharge) {
          return (
            <>
              <BasketItem key={`${key}-${item.id}`} {...item} />
              {item.productsFreeOfCharge.map(prod => (
                <BasketItem key={`${key}-${prod.id}`} {...prod} increaseDecreaseAct={false} quantity={item.quantity} />
              ))}
            </>
          );
        }
        return <BasketItem key={`${key}-${item.id}`} {...item} />;
      })}
  </Stack>
);
