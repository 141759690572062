import { AccordionPanel, forwardRef, Table, Tbody, Td, Tr, useMediaQuery } from '@chakra-ui/react';
import { rem } from 'polished';

import { TAccordionContent } from './SWBasket.types';

export const CustomAccordionPanel = forwardRef<TAccordionContent, 'div'>(({ bundles, ...props }, ref) => {
  const [isBetween768And1024] = useMediaQuery(`(min-width: ${rem('768px')}) and (max-width: ${rem('1024px')})`);

  return (
    <AccordionPanel ref={ref} {...props} w="full" px="0">
      <Table size="sm">
        <Tbody>
          {bundles?.map((item, key) => (
            <Tr key={`${key}-${item.id}`}>
              <Td
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="0"
                border="none"
                w={isBetween768And1024 ? rem('105px') : '6%'}
              ></Td>
              <Td
                fontSize={{ base: 'sm', md: 'lg' }}
                lineHeight="5"
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="2"
                w={isBetween768And1024 ? 'auto' : '35%'}
              >
                {item.name}
              </Td>
              <Td
                textAlign={isBetween768And1024 ? 'left' : 'right'}
                fontSize={{ base: 'sm', md: 'lg' }}
                lineHeight={{ base: '4', md: '5' }}
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="2"
                w={isBetween768And1024 ? '0%' : 'auto'}
              ></Td>
              <Td
                textAlign={isBetween768And1024 ? 'left' : 'right'}
                display={{ base: 'none', md: 'table-cell' }}
                fontSize={{ base: 'sm', md: 'lg' }}
                lineHeight={{ base: '4', md: '5' }}
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="2"
                w={isBetween768And1024 ? '20%' : '15%'}
              ></Td>
              <Td
                textAlign={isBetween768And1024 ? 'left' : 'right'}
                display={{ base: 'none', md: 'table-cell' }}
                fontSize={{ base: 'sm', md: 'lg' }}
                lineHeight={{ base: '4', md: '5' }}
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="2"
                w={isBetween768And1024 ? '20%' : '15%'}
              ></Td>
              <Td
                pt={{ base: '1', md: '4' }}
                pb="1"
                px="2"
                w="22%"
                minW={isBetween768And1024 ? rem('135px') : 'auto'}
              />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </AccordionPanel>
  );
});
