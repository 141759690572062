export default {
  selectedLang: 'Português',
  Lang: 'Selecione sua lingua',
  AddToBasket: 'Aderir',
  UpdateBasket: 'Atualizar Adesão',
  RemoveFromBasket: 'Remover',
  ConfigureYourPack: 'Configurar seu pacote',
  nif: 'NIF',
  account: 'Conta',
  pricingRedSentence:
    '* Apenas um tipo de edição do Trend Micro poderá ser subscrito (sem limite de número de licenças).',
  priceSuffix: 'sem IVA',
  pricingBlackSentence:
    'Ao escolher "Aderir" irá ter acesso, através de login com as suas credenciais My Vodafone, à Vodafone Cloud Store. Consulte ',
  and: 'e',
  termsAndConditions: 'Termos e Condições',
  privacySupplement: 'Suplemento de Privacidade',
  billingPlataform: {
    blocklist: {
      title: 'Infelizmente não é possível avançar',
      description: 'Por favor entre em contacto com o seu gestor de comunicações ou ligue 16914.',
      buttonCTA: 'Voltar',
    },
    noAccounts: {
      title: 'Sem contas Vodafone elegíveis para avançar',
      description:
        'Não foram encontradas contas Vodafone, associadas ao seu acesso My Vodafone, que permitam avançar com esta subscrição. Por favor valide as contas associadas na sua área My Vodafone. Em caso de dúvida, por favor entre em contacto com o seu gestor de comunicações ou ligue 16914.',
      buttonCTA: 'Ir para área My Vodafone',
    },
  },
  totalCost: 'Custo total',
  monthly: 'Mensal',
  currency: '€',
  goToBasket: 'Finalizar compra',
};
