import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import Button from '@vfuk/core-button';
import Image from '@vfuk/core-image';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { FC, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { DetailsAndPricing } from '../../DetailsAndPricing';
import { InfoAndCta } from '../../InfoAndCta';
import { CardHeading, CTAWrapper, ImageWrap, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps, DetailsAndPricingProps } from '../../SWPricingCard.types';
import { FCWrapper, FlippedWrapper } from './SWFlippingCard styles';
const SWFlippingCard: FC<CardTypeProps> = ({
  item,
  mainItem,
  selectedItem,
  itemStepName,
  shouldChangeTheme,
  mergedProduct,
}) => {
  const { width } = useWindowSize();
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  const { productPageUrl, shortDescription, description, thumbnailIcon, detailAndPricing, dxlInformation } =
    selectedItem;
  const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';

  const handleFlip = () => setIsFlipped(!isFlipped);

  return (
    <>
      {dxlInformation ? (
        <CardHeading pb={rem('14px')} pt={{ base: rem('27px'), md: rem('21px') }} px={5}>
          <Stack direction="row" spacing={5}>
            {thumbnailIconSrc && (
              <ImageWrap>
                <Image
                  alt={thumbnailIcon?.title || ''}
                  sm={{
                    src: thumbnailIconSrc,
                    width: '48px',
                    height: '48px',
                  }}
                  md={{
                    src: thumbnailIconSrc,
                    width: '64px',
                    height: '64px',
                  }}
                />
              </ImageWrap>
            )}
            <Box minH={rem('90px')}>
              <Heading
                as="h2"
                color={shouldChangeTheme ? 'white' : 'mineShaft'}
                fontSize={width >= 768 ? rem('28px') : rem('24px')}
                fontWeight="bold"
                lineHeight={{ base: rem('30px'), md: rem('36px') }}
                mb={0}
              >
                {dxlInformation?.name}
              </Heading>
            </Box>
          </Stack>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}

      <FCWrapper isFlipped={isFlipped}>
        {isFlipped ? (
          <FlippedWrapper isFlipped={isFlipped}>
            <Text
              color={'white'}
              fontSize={{ base: 'md', md: 'lg' }}
              lineHeight={rem('24px')}
              marginRight={5}
              marginLeft={5}
              mt={{ base: 5, md: 0 }}
            >
              {description?.description}
            </Text>
            <Button
              text={'Visit product page'}
              appearance="primary"
              href={productPageUrl || '/marketplace/product-list'}
              onClick={() => {
                return; // Just to not make it blank and still get the styling
              }}
              id={'VisitProductButton'}
            />
            <Button text={'Show Less'} appearance="alt1" onClick={handleFlip} id={'FlipButton'} />
          </FlippedWrapper>
        ) : (
          <>
            <Text
              color={'mineShaft'}
              fontSize={{ base: 'md', md: 'lg' }}
              lineHeight={rem('18px')}
              marginRight={5}
              marginLeft={5}
              mt={{ base: 5, md: 0 }}
            >
              {shortDescription?.shortDescription}
            </Text>
            {dxlInformation && detailAndPricing && (
              <DetailsAndPricing
                itemStepName={itemStepName ? itemStepName : ''}
                item={selectedItem}
                color={shouldChangeTheme ? 'white' : undefined}
                useSimplePrice={mergedProduct}
                {...{ selectedEdition, setSelectedEdition }}
              />
            )}
            <Button text={'Show More'} appearance="alt1" onClick={handleFlip} id={'FlipButton'} />
          </>
        )}
      </FCWrapper>
      <CTAWrapper>
        {item?.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWFlippingCard;
