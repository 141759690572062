import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const Wrapper = styled.div`
  [class*='ContainerWithLabelstyle__ContainerWithLabel'] {
    height: 100%;
    box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.16);
    min-height: ${rem('362px')};

    > *:last-child {
      &,
      &::after {
        border-radius: 0;
      }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      min-height: ${rem('162px')};
    }
  }

  [class*='Interactionstyle__BlankInteraction'][class*='Containerstyle__Container'] {
    padding: ${rem('20px')};

    &::after {
      border-color: #007c92;
    }

    [class*='Containerstyle__ContainerContent'] {
      display: flex;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        flex-direction: column;

        picture {
          display: grid;
          min-height: ${rem('90px')};
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  margin: ${rem('10px')} 0;
`;

export const ImgWrapper = styled(Flex)`
  align-items: center;
  background-color: #eaeaea;
  width: ${rem('90px')};
  height: ${rem('90px')};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: ${rem('122px')};
    height: ${rem('122px')};
  }

  img {
    max-width: ${rem('90px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: ${rem('122px')};
      max-height: ${rem('122px')};
      max-width: ${rem('122px')};
    }
  }
`;

export const Content = styled.div`
  max-width: ${rem('518px')};
  padding-inline: 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-inline: ${rem('20px')};
  }

  a {
    color: var(--mineShaft);
    text-decoration: underline;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      text-decoration: none;
      margin-left: ${rem('107px')};
      position: absolute;
      top: 0;
    }

    :hover {
      color: var(--mineShaft);
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        text-decoration: none;
      }
    }

    div:nth-of-type(2) {
      font-size: ${rem('28px')};
      line-height: ${rem('33px')};
    }
  }

  p {
    font-size: ${rem('14px')};
    line-height: ${rem('18px')};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      margin-top: ${rem('20px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('18px')};
      line-height: ${rem('24px')};
    }
  }
`;

export const ContentText = styled.p`
  color: var(--mineShaft);
  font-size: ${rem('18px')};
  line-height: ${rem('24px')};
  margin: ${rem('6px')} 0 0;
`;

export const InfoAndCta = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    flex-grow: 1;
    justify-content: flex-end;
    padding-top: ${rem('14px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    align-items: flex-end;
    margin-left: auto;
    max-width: ${rem('221px')};
    width: 100%;
  }

  [class*='CardSandwichSlotstyle__CardSandwichSlot'] {
    width: 100%;
    padding: 0;

    [class*='CardSandwichSlotstyle__SandwichContainer'] {
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        margin-bottom: ${rem('12px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin-bottom: ${rem('8px')};
      }
    }
  }

  [class*='CardSandwichSlotstyle__SandwichWrapper'] {
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-right: ${rem('4px')};
      margin-top: ${rem('-4px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      margin-right: ${rem('30px')};
    }
  }

  [class*='Sandwichstyle__TextWrapper']:nth-of-type(1) {
    margin-bottom: ${rem('5px')};
    font-size: ${rem('16px')};
    line-height: ${rem('20px')};
  }

  [class*='Sandwichstyle__MainContent'] {
    display: block;

    span:nth-of-type(1),
    span:nth-of-type(2) {
      line-height: ${rem('36px')};
    }
    span:nth-of-type(1) {
      font-size: ${rem('28px')};
    }
    span:nth-of-type(2) {
      font-size: ${rem('46px')};
    }
    span:nth-of-type(3) {
      font-size: ${rem('16px')};
      line-height: ${rem('20px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin-inline-start: ${rem('3px')};
        width: 30%;
        display: inline-block;
      }
    }
  }

  [class*='Buttonstyle__Button-sc'] {
    height: ${rem('50px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      width: ${rem('220px')};
      height: ${rem('50px')};
    }
  }
`;
