import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ArticleCard from '@vfuk/core-article-card';
import Heading from '@vfuk/core-heading';
import Modal from '@vfuk/core-modal';
import { OverlayCloseSource } from '@vfuk/core-overlay-controller';
import SimpleGrid from '@vfuk/core-simple-grid';
import { navigate } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC, useEffect, useState } from 'react';

import { useAllowedUris } from '@/hooks';
import { useModalContext } from '@/hooks/modal/useModal';

import { ArticleCardWrapper, ErrorModalWrapper } from './SWErrorModal.styles';
import { ErrorCardProps, SWErrorModalProps } from './SWErrorModal.types';

const SWErrorModal: FC<SWErrorModalProps> = ({ richTextHeading, errorCards }) => {
  const { isErrorModalOpen, toggleModal, errorInfo } = useModalContext();
  const [modalCardsImageSrc, setModalCardsImageSrc] = useState<string | undefined>('');
  const [selectedCard, setSelectedCard] = useState<ErrorCardProps | null>(null);

  const allowedUris = useAllowedUris();
  const handleCloseCB = (source: OverlayCloseSource | undefined) => {
    toggleModal(false, 'Error');
    source === 'MODAL_CROSS_CLICK' && allowedUris.isSelectAccountPage && navigate('/');
  };

  useEffect(() => {
    const matchingError = errorCards?.find(
      card =>
        card.requestUrl === errorInfo.requestUrl &&
        card.statusNumber === errorInfo.statusCode &&
        (card.shouldUseApiMessage ? card.apiMessage === errorInfo.statusType : true)
    );
    const defaultCard = errorCards?.find(card => card.name === 'Default Error Card');

    const currentCard = matchingError || defaultCard || (errorCards && errorCards[0]);
    setSelectedCard(currentCard);
    setModalCardsImageSrc(getSrc(currentCard?.cardImage) || getImage(currentCard?.cardImage)?.placeholder?.fallback);
  }, [errorInfo, isErrorModalOpen]);

  return (
    <Modal
      id="ErrorModal"
      srName="Error Modal"
      isOpen={isErrorModalOpen}
      size={4}
      animate
      isClosable
      onCloseCb={handleCloseCB}
    >
      <ErrorModalWrapper>
        {richTextHeading && (
          <Heading level={3} justify="center">
            {documentToReactComponents(JSON.parse(richTextHeading.raw))}
          </Heading>
        )}

        <SimpleGrid
          columns={{
            sm: 1,
            md: 1,
          }}
          spacing={0}
        >
          <ArticleCardWrapper>
            <ArticleCard
              appearance="primary"
              level={2}
              primaryImage={{
                sm: {
                  src: modalCardsImageSrc || '',
                },
                alt: '',
              }}
              primaryButton={{
                text: selectedCard?.primaryButton?.label || '',
                href: selectedCard?.primaryButton?.url || '',
              }}
              headingText={selectedCard?.headingText || ''}
              text={selectedCard?.text || ''}
            />
          </ArticleCardWrapper>
        </SimpleGrid>
      </ErrorModalWrapper>
    </Modal>
  );
};

export default SWErrorModal;
