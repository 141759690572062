import { Box } from '@chakra-ui/react';
import IconButton from '@vfuk/core-icon-button';
import { FC, useState } from 'react';

import { updateUserBasket, useAppDispatch } from '@/store';

import { QuantityActions, SQuantityNumber } from './SWBasket.styles';
import { TQuantity } from './SWBasket.types';

export const Quantity: FC<TQuantity> = ({ item }: TQuantity) => {
  const dispatch = useAppDispatch();
  const minAmount = item?.__typename === 'ContentfulMarketplaceProductBundle' && item?.msftProduct ? 5 : 1;
  const [currentQuantity, setCurrentQuantity] = useState<number>(item?.quantity || 0);

  const serviceExtra =
    item?.__typename === 'ContentfulMarketplaceProductBundle' &&
    item?.msftProduct &&
    item?.configTab === 'Service Extra' &&
    currentQuantity <= 5;
  const microsoftMigration = currentQuantity === 5 && minAmount === 5 && item.name === 'Microsoft 365 Migration';
  const singleItem = item.buttonsAction === 'addToBasket';

  const handleDecreaseQty = () => {
    if (currentQuantity && currentQuantity > 0) {
      dispatch(
        updateUserBasket({
          ...item,
          quantity:
            item.__typename === 'ContentfulMarketplaceProductBundle' && item.msftProduct && currentQuantity === 5
              ? minAmount
              : currentQuantity - 1,
        })
      );
      if (microsoftMigration) {
        setCurrentQuantity(minAmount);
      } else {
        setCurrentQuantity(currentQuantity - 1);
      }
    }
  };

  const handleIncreaseQty = () => {
    if (currentQuantity > 0) {
      dispatch(
        updateUserBasket({
          ...item,
          quantity: currentQuantity + 1,
        })
      );
      setCurrentQuantity(currentQuantity + 1);
    }
  };

  return (
    <Box
      sx={{
        button: {
          visibility: item.increaseDecreaseAct && !singleItem ? 'visible' : 'hidden',
        },
      }}
    >
      <QuantityActions>
        <IconButton
          {...((serviceExtra || microsoftMigration || currentQuantity === 1) && {
            state: 'disabled',
          })}
          size={1}
          shape="circle"
          icon={{ name: 'minus' }}
          onClick={handleDecreaseQty}
        />
        <SQuantityNumber>
          {!item.increaseDecreaseAct && item.quantity ? item.quantity : currentQuantity}
        </SQuantityNumber>
        <IconButton size={1} shape="circle" icon={{ name: 'plus' }} onClick={handleIncreaseQty} />
      </QuantityActions>
    </Box>
  );
};
