import '@vf-dcl/vodafone-ws2/lib/ws2.css';

import { Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { OverlayProvider as SWOverlayProvider } from '@source-web/overlay-controller';
import { ConsumerAccessWrapper } from '@vf-opf/consumer-access';
import { OverlayProvider } from '@vfuk/core-overlay-controller';
import WS10Theme from '@vfuk/core-theme-ws10';
import { withPrefix } from 'gatsby';
import { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import CheckIdleTime from '@/components/Global/CheckIdleTime';
import LoadingCheckoutFrame from '@/components/Global/LoadingCheckoutModal';
import SpinnerFrame from '@/components/Global/SpinnerModal';
import SWFooter from '@/components/Global/SWFooter/SWFooter';
import SWTopNavigation from '@/components/Global/SWTopNavigation/SWTopNavigation';
import i18n, { availableLangs } from '@/languages/i18n';
import { UserSessionProvider } from '@/contexts/UserSessionContext';
import { addDynamicBodyContent } from '@/helpers/DynamicContentHelpers';
import ModalProvider from '@/hooks/modal';
import useDocumentPathname from '@/hooks/useDocumentPathname';
import { useSiteConfig } from '@/hooks/useSiteConfig';
import ReduxWrapper from '@/store/ReduxWrapper';
import { PageBuilderProps } from '@/templates/page-builder';

import { globalStyles } from './MainLayout.styles';
import SWSectionHeroBannerUpload from '@/components/pageBuilder/Sections/SWSectionHeroBannerUpload';

WS10Theme.setBaseAssetLocation(withPrefix('/assets/ws10/'));

type TMainLayout = Partial<Pick<PageBuilderProps['pageBuilderData'], 'isDefaultPage' | 'isProtectedPage'>> & {
  children: React.ReactNode | React.ReactNode[];
};

const MainLayout: FC<TMainLayout> = ({ isDefaultPage, isProtectedPage, children }) => {
  const data = useSiteConfig();
  const pathname = useDocumentPathname();
  const { i18n: i18nObject } = useTranslation();

  useEffect(() => {
    const browserLang: string | undefined = navigator.language.includes('-') // detect browser lag ex pt-PT or en-EN etc.
      ? navigator.language.split('-').shift()
      : navigator.language ?? 'en'; // removing '-'
    i18nObject.changeLanguage(browserLang && availableLangs.includes(browserLang) ? browserLang : 'en'); // set browser lang
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TODO: Need to check the correct types for this line
    if (window.seoPageChange) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TODO: Need to check the correct types for this line
        window.seoPageChange();
      } catch (e) {
        console.error(e);
      }
    }
  }, [pathname]);

  useEffect(() => {
    addDynamicBodyContent(data);
  }, []);

  let useDefaultTemplate = true;
  let templateText;
  // NB - this code only decides whether to NOT add the default template.
  // The decision whether or not to add the custom template is made in html.js.
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    templateText = require('raw-loader!../../../vhub-opco-templates/index.template.html').default;
  } catch (e) {
    templateText = '';
  }

  if (templateText && templateText.length > 0) {
    useDefaultTemplate = false;
  }

  const currentEnv = `${process.env.GATSBY_ENV}`;

  return (
    <ConsumerAccessWrapper
      currentEnv={currentEnv !== undefined ? currentEnv : 'production'}
      restrictedEnvs={['development']}
    >
      <ReduxWrapper>
        <I18nextProvider i18n={i18n}>
          <EmotionThemeProvider theme={WS10Theme}>
            <ThemeProvider theme={WS10Theme}>
              <SWOverlayProvider>
                <OverlayProvider>
                  <ModalProvider>
                    <UserSessionProvider>
                      <WS10Theme.globalStyles />
                      <CheckIdleTime isProtectedPage={isProtectedPage}>
                        {useDefaultTemplate && !isDefaultPage && <SWTopNavigation />}
                        <Global styles={globalStyles} />
                        {children}
                        {useDefaultTemplate && !isDefaultPage && <SWFooter />}
                      </CheckIdleTime>
                      <SpinnerFrame />
                      <LoadingCheckoutFrame />
                    </UserSessionProvider>
                  </ModalProvider>
                </OverlayProvider>
              </SWOverlayProvider>
            </ThemeProvider>
          </EmotionThemeProvider>
        </I18nextProvider>
      </ReduxWrapper>
    </ConsumerAccessWrapper>
  );
};

export default MainLayout;
