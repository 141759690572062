import { BannerBodyProps } from '@vfuk/core-banner-body/dist/BannerBody.types';
import StandardBanner from '@vfuk/core-standard-banner';
import { getImage, getSrc } from 'gatsby-plugin-image';

import * as Styles from './styles/SWStandardBanner.styles';
import { SWStandardBannerProps } from './types/SWStandardBanner.types';

const SWStandardBanner = (props: SWStandardBannerProps) => {
  const { heading, subheading, backgroundImage, backgroundOverrideClass, primaryButton } = props;
  const { mobileBackgroundImage, imageTitle } = backgroundImage;

  const mobileBackgroundImageSrc = getSrc(mobileBackgroundImage);
  const mobileBackgroundImagePlaceholder = getImage(mobileBackgroundImage)?.placeholder?.fallback;

  const imageTitleSrc = getSrc(imageTitle);
  const imageTitlePlaceholder = getImage(imageTitle)?.placeholder?.fallback;

  return (
    <Styles.StandardBannerWrapper>
      <div className={backgroundOverrideClass}>
        <StandardBanner
          heading={{ text: heading || '', level: 1 }}
          paragraph={{ text: subheading || '' }}
          backgroundColor=""
          backgroundImage={{
            sm: {
              src: mobileBackgroundImageSrc || mobileBackgroundImagePlaceholder || '',
            },
            md: {
              src: imageTitleSrc || imageTitlePlaceholder || '',
            },
            lg: {
              src: imageTitleSrc || imageTitlePlaceholder || '',
            },
            xl: {
              src: imageTitleSrc || imageTitlePlaceholder || '',
            },
          }}
          {...(primaryButton && {
            primaryButton: {
              text: primaryButton.label,
              href: primaryButton.url,
              ...(primaryButton.rank && {
                appearance: primaryButton.rank,
              }),
            } as BannerBodyProps['primaryButton'],
          })}
        />
      </div>
    </Styles.StandardBannerWrapper>
  );
};

export default SWStandardBanner;
