export default {
  selectedLang: 'English',
  Lang: 'Choose your language',
  AddToBasket: 'Add To Basket',
  UpdateBasket: 'Update Basket',
  RemoveFromBasket: 'Remove from Basket',
  ConfigureYourPack: 'Configure Your Pack',
  nif: 'NIF',
  account: 'Account',
  pricingRedSentence: `* For this product, only one Edition can be selected and purchased (this doesn't have effect on the licence
  quantity).`,
  priceSuffix: 'ex.VAT',
  pricingBlackSentence: '',
  and: '',
  termsAndConditions: '',
  privacySupplement: '',
  billingPlataform: {
    blocklist: {
      title: `Unfortunately, it's not possible to go any further`,
      description: 'Please contact your communications manager or call 16914.',
      buttonCTA: 'Back',
    },
    noAccounts: {
      title: 'No eligible Vodafone accounts',
      description:
        'No Vodafone accounts associated with your My Vodafone access have been found that allow you to proceed with this subscription. Please validate the associated accounts in your My Vodafone area. If you have any questions, please contact your communications manager or call 16914.',
      buttonCTA: 'Go to My Vodafone',
    },
  },
  totalCost: 'Total cost',
  monthly: 'Monthly',
  currency: '£',
  goToBasket: 'Go to Basket',
};
