import { forwardRef, Text, TextProps } from '@chakra-ui/react';
import { rem } from 'polished';

const DetailInfo = forwardRef<TextProps, 'div'>(({ children, ...props }, ref) => (
  <Text
    ref={ref}
    as="h6"
    color="mineShaft"
    fontSize={rem('22px')}
    fontWeight="bold"
    letterSpacing={rem('1px')}
    lineHeight={6}
    my={0}
    noOfLines={2}
    sx={{
      small: {
        fontSize: 7,
      },
    }}
    {...props}
  >
    {children}
  </Text>
));

export default DetailInfo;
