import { Flex, Stack } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import Button from '@vfuk/core-button';
import IconButton from '@vfuk/core-icon-button';
import { InteractionEvent } from '@vfuk/core-interaction/dist/Interaction.types';
import TextInput from '@vfuk/core-text-input';
import { TextInputProps } from '@vfuk/core-text-input/dist/TextInput.types';
import { navigate, withPrefix } from 'gatsby';
import path from 'path';
import { rem } from 'polished';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadLocalStorage } from 'usehooks-ts';

import { useAllowedUris } from '@/hooks';
import { useModalContext } from '@/hooks/modal/useModal';
import {
  addToBasket,
  isInBasket,
  itemQuantityInBasket,
  removeFromBasket,
  setBasketSummaryOpen,
  setRedirectPath,
  updateUserBasket,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { ProductBundle } from '@/types/Product.types';

import { ButtonWrapper, IconButtonWrapper, InputWrapper } from './SWPricingCard.styles';
import { InfoAndCtaProps } from './SWPricingCard.types';

export const InfoAndCta: FC<
  InfoAndCtaProps & { buttonStyling: { backgroundColor?: string; textColor?: string }; useSimplePrice?: boolean }
> = ({ item, itemStepName, buttonStyling, useSimplePrice, mainItem }) => {
  const { productId, editionIds, showDivider, buttonsAction, msftProduct, slug } = item;
  const mainProduct = useMemo(() => {
    if (!!mainItem && mainItem.productId !== item.productId) {
      return mainItem;
    }
  }, [mainItem, item]) as ProductBundle;
  const dataSelector = item.name || item.dxlInformation?.name || '';
  const dispatch = useAppDispatch();
  const userBasket = useAppSelector(({ userBasket }) => userBasket.data);
  const { user } = useAppSelector(({ userSession }) => userSession);
  const allowedUris = useAllowedUris();
  const { pathname } = useLocation();
  const { toggleModal } = useModalContext();
  const addToBasketActs = ['addToBasket', 'addToBasketWithQty', 'updateBasketWithQty'];
  const { t } = useTranslation();
  const initialQty =
    useMemo(() => {
      if ((itemStepName?.includes('Bundle') && msftProduct) || pathname.includes('nce-microsoft-365-business')) {
        return 1;
      }

      if ((itemStepName === 'Service Extra' && msftProduct) || pathname.includes('apphelp-microsoft-365-migration')) {
        return 5;
      }
    }, [itemStepName, msftProduct]) || 1;

  //This is for tealium events
  const thirdEntryPrice =
    useMemo(() => {
      if (useSimplePrice) {
        return item.dxlInformation?.price[0].price;
      } else if (
        (!useSimplePrice && itemStepName === 'Service Extra') ||
        pathname.includes('apphelp-microsoft-365-migration')
      ) {
        return item.dxlInformation?.price.find(price => price.name === 'Cost2')?.price;
      } else {
        return item.dxlInformation?.price.find(price => price.name === 'Cost1')?.price;
      }
    }, [useSimplePrice, item]) || '';

  const fourthEntryPrice =
    (item.detailAndPricing?.fourthEntry && item.dxlInformation?.price.find(price => price.name === 'Cost2')?.price) ||
    '';

  const [stepName, setStepName] = useState<string | undefined>(itemStepName);

  // NOTE: Minimum quantity should be being fetched from the api instead doing a rule for it, as we don't have this
  // value coming from the api yet, then we need to do this rule.
  const minAmount = initialQty;
  const [quantity, setQuantity] = useState<number>(initialQty);

  const [warningState, setWarningState] = useState<TextInputProps['state']>();

  const stackableProduct = useReadLocalStorage<boolean>('vf_stackable');
  const isWithQty = ['addToBasketWithQty', 'updateBasketWithQty'].includes(String(buttonsAction));

  const nextStepSlug = useMemo(() => {
    const stepString = 'step-';
    let step;
    if (pathname.includes(stepString)) {
      step = pathname.includes(stepString) && pathname.substring(pathname.length - stepString.length - 1);
    } else {
      step = `${pathname}/${stepString}`;
    }
    return (
      step &&
      withPrefix(
        pathname.includes(stepString)
          ? `${pathname.substring(0, pathname.indexOf(step))}${stepString}1`
          : `${pathname}/${stepString}1`
      )
    );
  }, [pathname]);

  const insideConfigPage = useMemo(() => {
    return !pathname.includes('/marketplace/office-anywhere/configuration');
  }, [pathname]);

  const buttonText = useCallback(() => {
    if (!isInBasket(userBasket, item) && buttonsAction && addToBasketActs.includes(buttonsAction)) {
      return t('AddToBasket');
    }

    if (buttonsAction === 'configureYourPack') {
      return t('ConfigureYourPack');
    }

    if (isInBasket(userBasket, item) && buttonsAction && addToBasketActs.includes(buttonsAction)) {
      const itemQuantity = itemQuantityInBasket(userBasket, item);

      if (quantity === 0 || itemQuantity === quantity) {
        return t('RemoveFromBasket');
      }

      return t('UpdateBasket');
    }

    return t('AddToBasket');
  }, [userBasket, item, quantity, buttonsAction]);

  const handleButtonAction = (event: InteractionEvent) => {
    event.preventDefault();
    if (!isInBasket(userBasket, item) && buttonsAction && addToBasketActs.includes(buttonsAction)) {
      if (!stackableProduct) {
        dispatch(removeFromBasket(editionToRemove.toString()));
      }

      const qty = quantity === 0 ? initialQty : quantity;
      setQuantity(qty);

      dispatch(
        addToBasket({
          ...item,
          quantity: qty,
          ...(itemStepName && mainProduct && { configTab: itemStepName, mainProduct }),
        })
      );

      if (
        msftProduct &&
        nextStepSlug &&
        insideConfigPage &&
        !slug.includes('apphelp-microsoft-365-migration') &&
        !pathname.includes('business-boost')
      ) {
        if (user?.userId) {
          navigate(nextStepSlug);
        } else {
          dispatch(setRedirectPath(nextStepSlug));
        }
      } else {
        dispatch(setBasketSummaryOpen(true));
      }
    }

    if (
      isInBasket(userBasket, item) &&
      buttonsAction &&
      addToBasketActs.includes(buttonsAction) &&
      (quantity === 0 || itemQuantityInBasket(userBasket, item) === quantity)
    ) {
      dispatch(removeFromBasket(productId));
    }

    if (
      isInBasket(userBasket, item) &&
      buttonsAction &&
      ['addToBasketWithQty', 'updateBasketWithQty'].includes(buttonsAction) &&
      user?.userId
    ) {
      if (!stackableProduct) {
        dispatch(removeFromBasket(editionToRemove.toString()));
      }

      const qty = quantity >= minAmount && quantity !== 1 ? quantity : quantity < 1 ? 0 : minAmount;
      if (qty > 0) {
        setQuantity(qty);
        dispatch(
          updateUserBasket({
            ...item,
            quantity: qty,
            ...(itemStepName && mainProduct && { configTab: itemStepName, mainProduct }),
          })
        );
        dispatch(setBasketSummaryOpen(true));
      } else {
        isInBasket(userBasket, item) && dispatch(removeFromBasket(item));
      }

      if (
        msftProduct &&
        nextStepSlug &&
        insideConfigPage &&
        !slug.includes('apphelp-microsoft-365-migration') &&
        !pathname.includes('business-boost') &&
        qty > 0
      ) {
        navigate(nextStepSlug);
      }
    }

    if (buttonsAction === 'configureYourPack' && pathname.includes('business-boost')) {
      if (user?.userId) {
        navigate(withPrefix(path.join(allowedUris.paths.businessBoostPage, `/configuration/${slug}/step-0`)));
      } else {
        dispatch(
          setRedirectPath(withPrefix(path.join(allowedUris.paths.businessBoostPage, `/configuration/${slug}/step-0`)))
        );
      }
    } else if (buttonsAction === 'configureYourPack' && !pathname.includes('business-boost')) {
      if (user?.userId) {
        navigate(
          msftProduct
            ? withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${slug}/step-1`))
            : withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${slug}/step-0`))
        );
      } else {
        dispatch(
          setRedirectPath(
            msftProduct
              ? withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${slug}/step-1`))
              : withPrefix(path.join(allowedUris.paths.officeAnywherePage, `/configuration/${slug}/step-0`))
          )
        );
      }
    }

    if (!user?.userId) {
      toggleModal(true, 'Login');
    }
  };

  const editionIdsToCheck: string[] = [];

  for (let i = 0; i < userBasket.items?.length; i++) {
    const editionIdsInBasket = userBasket.items.filter(edition => edition)[i].productId;
    editionIdsToCheck.push(editionIdsInBasket);
  }

  const notSelectedEditionIds = editionIds?.filter(editionId => editionId !== productId);
  const editionToRemove = notSelectedEditionIds.filter(editionId => editionIdsToCheck.includes(editionId));

  const handleDecreaseQty = () => {
    setQuantity(prev => {
      setWarningState(prev < minAmount ? 'error' : 'success');
      if ((buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') && quantity > minAmount) {
        return prev - 1;
      }
      if (
        (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') &&
        quantity <= minAmount
      ) {
        return 0;
      }
      return prev;
    });
  };

  const handleIncreaseQty = () => {
    setQuantity(prev => {
      setWarningState(prev < minAmount ? 'error' : 'success');
      if (quantity === 0) {
        return prev + minAmount === 5 ? 5 : 1;
      }
      if (buttonsAction === 'addToBasket' && quantity >= 0) {
        return prev + minAmount;
      }
      if (
        (buttonsAction === 'addToBasketWithQty' || buttonsAction === 'updateBasketWithQty') &&
        quantity >= minAmount
      ) {
        return prev + 1;
      }
      if (buttonsAction === 'updateBasketWithQty' && quantity < minAmount) {
        return minAmount;
      }
      return prev;
    });
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const number = Number(value);
    setWarningState(number < minAmount ? 'error' : 'success');
    setQuantity(number ? number : 0);
  };

  useEffect(() => {
    if (isInBasket(userBasket, item)) {
      const { quantity } = userBasket.items.filter(item => item.productId === productId && item)[0] || 0;
      setWarningState(quantity && quantity < minAmount ? 'error' : 'success');
      setQuantity(quantity ? quantity : minAmount === 5 ? 5 : 1);
    }
  }, [userBasket]);

  const isButtonDisabled = () => {
    if (item.productType !== 'addon') {
      return false;
    }
    const bundleExists = userBasket.items.some(
      basketItem => basketItem.productType === 'bundle' && basketItem.solutionType === item.solutionType
    );

    // Disable addon buttons if a bundle with the same solutionType doesn't exist doesn't exist
    return !bundleExists;
  };

  return (
    <Flex direction="column" mt="auto" pt={showDivider ? 5 : 5} pb={5} px={5}>
      <Stack
        direction="row"
        spacing={5}
        {...(showDivider && {
          borderTop: `${rem('1px')} solid`,
          borderColor: 'disabled.default',
          pt: rem('20px'),
        })}
      >
        {isWithQty && (
          <InputWrapper>
            <IconButtonWrapper ml={1}>
              <IconButton
                {...(quantity === 0 && { state: 'disabled' })}
                srText="Decrease quantity"
                size={1}
                icon={{ name: 'minus' }}
                onClick={handleDecreaseQty}
                dataAttributes={{
                  value: String(quantity),
                }}
                dataSelectorPrefix={dataSelector.toLowerCase()}
              />
            </IconButtonWrapper>

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore: Necessary to use TextInput instead the suggested component */}
            <TextInput
              id="action"
              type="number"
              placeholder="0"
              value={String(quantity)}
              onChange={handleChangeQuantity}
              {...(dataSelector && {
                dataAttributes: {
                  'data-stepname': stepName?.includes('Bundle') ? 'Bundle' : stepName,
                },
                dataSelectorPrefix: dataSelector.toLowerCase(),
              })}
            />
            <IconButtonWrapper mr={1} right={0}>
              <IconButton
                srText="Increase quantity"
                size={1}
                icon={{ name: 'plus' }}
                onClick={handleIncreaseQty}
                dataAttributes={{
                  value: String(quantity),
                }}
                dataSelectorPrefix={dataSelector.toLowerCase()}
              />
            </IconButtonWrapper>
          </InputWrapper>
        )}

        <ButtonWrapper
          inBasket={isInBasket(userBasket, item)}
          buttonsAction={buttonsAction}
          buttonBackgroundColor={buttonStyling?.backgroundColor}
          buttonTextColor={buttonStyling?.textColor}
        >
          <Button
            {...(dataSelector && {
              dataAttributes: {
                'data-price': `€${thirdEntryPrice}${fourthEntryPrice && ', €' + fourthEntryPrice}`,
              },
              dataSelectorPrefix: `pricing card-${dataSelector.toLowerCase()}`,
            })}
            id="basketButtonAction"
            text={buttonText()}
            state={isButtonDisabled() ? 'disabled' : ''}
            onClick={handleButtonAction}
          />
        </ButtonWrapper>
      </Stack>
    </Flex>
  );
};
