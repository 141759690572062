import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { rem } from 'polished';

const Container = forwardRef<FlexProps, 'div'>(({ children, ...props }, ref) => (
  <Flex ref={ref} direction="column" align="center" w="full" {...props}>
    <Flex
      direction="column"
      alignContent="center"
      w="full"
      maxW={{
        base: rem('375px'),
        sm: rem('644px'),
        md: '3xl',
        // lg: rem('1180px'), // Correct way
        lg: rem('1212px'), // TODO: This is a temp fix, the correct way would be to talk with the designer for him to fix this
      }}
      px={4}
    >
      {children}
    </Flex>
  </Flex>
));

export default Container;
