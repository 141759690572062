import { Accordion, AccordionButton, AccordionItem, Box, Flex, VStack } from '@chakra-ui/react';
import ContainerWithLabel from '@vfuk/core-container-with-label';
import Heading from '@vfuk/core-heading';
import Icon from '@vfuk/core-icon';
import Image from '@vfuk/core-image';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { rem } from 'polished';
import { FC, useMemo } from 'react';

import { retrievePriceByTypeOrFirstOne } from '@/helpers/findPrices';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { useAppSelector, UserBasketItem, UserBasketItemBase } from '@/store';

import { CustomAccordionPanel } from './CustomAccordionPanel';
import { DeleteItem } from './DeleteItem';
import { Item } from './Item';
import { Price } from './Price';
import { Quantity } from './Quantity';
import { AccordionItemContents, GridContainer, ItemTitleWrapper, SummaryItem } from './SWBasket.styles';
import { BasketItemProps } from './SWBasket.types';

export const BasketItem: FC<BasketItemProps> = ({ increaseDecreaseAct = true, quantity, ...item }) => {
  const userBasket = useAppSelector(state => state.userBasket.data);
  const currentItem: UserBasketItem | undefined = userBasket.items.filter(
    currentItem => currentItem.productId === item?.productId
  )[0];
  const foundMailBox = currentItem?.dxlInformation?.price.find(price => price.unit === 'MAILBOX');
  const foundSession = currentItem?.dxlInformation?.price.find(price => price.unit === 'SESSION');

  const bundleIncludes =
    item.__typename === 'ContentfulMarketplaceProductBundle' && item.bundleIncludes ? item.bundleIncludes : [];
  const thumbnailIconSrc = getSrc(item.thumbnailIcon) || getImage(item.thumbnailIcon)?.placeholder?.fallback;

  const currentFlag = (tabName: UserBasketItemBase['configTab']) => {
    // NOTE: Bundle items will always start with "Bundle - "
    return tabName?.toLowerCase().includes('bundle') ? 'Bundle' : tabName;
  };

  const upFrontPrice = useMemo(() => {
    const qtd = quantity || 1;
    if (foundMailBox || foundSession) {
      if (
        currentItem.__typename === 'ContentfulMarketplaceProductBundle' &&
        currentItem.msftProduct &&
        currentItem.slug.includes('apphelp-microsoft-365-migration')
      ) {
        if (qtd <= 5) {
          return retrievePriceByTypeOrFirstOne(currentItem?.dxlInformation?.price, 'Cost2');
        } else {
          return (
            (retrievePriceByTypeOrFirstOne(currentItem?.dxlInformation?.price, 'Cost2') || 0) +
            (retrievePriceByTypeOrFirstOne(currentItem?.dxlInformation?.price, 'Cost1') || 0) * (qtd - 5)
          );
        }
      } else {
        return retrievePriceByTypeOrFirstOne(currentItem?.dxlInformation?.price, 'Cost1') * qtd;
      }
    }

    return 0;
  }, [foundMailBox, foundSession, currentItem]);

  const monthlyPrice = useMemo(() => {
    if (foundMailBox || foundSession) {
      return 0;
    } else {
      return retrievePriceByTypeOrFirstOne(currentItem?.dxlInformation?.price, 'Cost1');
    }
  }, [foundMailBox, foundSession, currentItem]);

  return (
    <SummaryItem productType={currentFlag(item.configTab)}>
      <Flex maxW={{ sm: 'md', md: 'none' }} w="full">
        <ContainerWithLabel label={currentFlag(item.configTab)}>
          <Accordion defaultIndex={[0]} allowMultiple color="var(--mineShaft)">
            <AccordionItem>
              {({ isExpanded }) => (
                <VStack
                  align="flex-start"
                  spacing="0"
                  sx={{
                    '> *': {
                      width: 'full',
                    },
                  }}
                >
                  <AccordionItemContents>
                    <Flex
                      flexBasis={{ md: '44%' }}
                      sx={{
                        picture: {
                          filter: !thumbnailIconSrc ? 'blur(4px)' : 'none',
                        },
                      }}
                    >
                      <Image
                        alt="image"
                        sm={{
                          src: thumbnailIconSrc || 'https://source.unsplash.com/FywWHwkizaQ/87x87',
                          width: '87px',
                          height: '87px',
                        }}
                      />

                      <ItemTitleWrapper>
                        <Heading level={4} weight={3} text={item?.dxlInformation?.name || item?.name} />

                        <Flex
                          {...(bundleIncludes.length === 0 && { display: 'none' })}
                          as="span"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          <AccordionButton p="0">
                            <Box as="span" display={{ md: 'none' }}>
                              See details
                            </Box>
                            <Box transform={isExpanded ? 'rotate(180deg)' : 'none'} transition="0.2s ease-out">
                              <Icon appearance="secondary" group="system" name={'chevron-down'} />
                            </Box>
                          </AccordionButton>
                        </Flex>
                      </ItemTitleWrapper>
                    </Flex>
                    {bundleIncludes.length > 0 && (
                      <CustomAccordionPanel
                        bundles={bundleIncludes}
                        display={{ '1lg': 'none' }}
                        pb={{ base: 0, md: rem('50px') }}
                      />
                    )}

                    <GridContainer>
                      <Item title="Upfront">
                        <Price value={checkForZeroOrNull(upFrontPrice, 'NA')} />
                      </Item>
                      <Item title="Monthly">
                        <Price value={checkForZeroOrNull(monthlyPrice * (quantity || 1), 'NA')} />
                      </Item>
                      <Item title="Quantity">
                        <Quantity item={{ ...currentItem, increaseDecreaseAct, quantity }} />
                      </Item>
                      <Item visibility={upFrontPrice === 0 && monthlyPrice === 0 ? 'hidden' : 'visible'}>
                        <DeleteItem {...{ item }} />
                      </Item>
                    </GridContainer>
                  </AccordionItemContents>
                  {bundleIncludes.length > 0 && (
                    <CustomAccordionPanel bundles={bundleIncludes} display={{ base: 'none', '1lg': 'block' }} pb="5" />
                  )}
                </VStack>
              )}
            </AccordionItem>
          </Accordion>
        </ContainerWithLabel>
      </Flex>
    </SummaryItem>
  );
};
