import styled from '@emotion/styled';
import { rem } from 'polished';

export const FCWrapper = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  display: flex;
  min-height: ${rem('330px')};
  transform-style: preserve-3d;
  transition: transform 0.6s;
  flex-direction: column;
  align-items: center;
  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(90deg);';
    }
  }};

  [class*='DetailItem'] {
    :nth-of-type(1n + 3) {
      margin-block: ${rem('8px')} 0;
    }
  }

  [class*='Interactionstyle'] {
    width: 90%;
    border-radius: ${rem('6px')};

    &[id='VisitProductButton'] {
      margin-bottom: ${rem('20px')};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
`;

export const FlippedWrapper = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  flex-direction: column;
  background-color: #333;
  color: var(--white);
  display: flex;
  align-items: center;
  padding: ${rem('20px')} 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: ${rem('340px')};
  }
  [class*='Interactionstyle__Button-sc'] {
    &[id='FlipButton'] {
      color: var(--white);
      border-color: var(--white);
    }
  }

  ${({ isFlipped }) => {
    if (isFlipped) {
      return 'transform: rotateY(-90deg);';
    }
  }};
`;
