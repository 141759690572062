import Image from '@vfuk/core-image';
import Paragraph from '@vfuk/core-paragraph';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC } from 'react';

import {
  ImageDataLikeProps,
  SWAvailableAppsCarouselIconsProps,
} from '@/components/pageBuilder/Sections/SWPricingContent/SWPricingContent.types';

import { AppsWrapper, CarouselWrapper, ImageDesc, ImageWrap, Title } from './SWAvailableAppsCarouselIcons.styles';

const SWAvailableAppsCarouselIcons: FC<SWAvailableAppsCarouselIconsProps> = ({ title, subtitle, icons }) => {
  const getIconSrc = (icon: ImageDataLikeProps) => {
    return (icon && getSrc(icon)) || getImage(icon)?.placeholder?.fallback || '';
  };
  console.log(subtitle);
  return (
    <>
      <AppsWrapper>
        <Title>{title}</Title>
        <Paragraph justify="center">{subtitle}</Paragraph>
      </AppsWrapper>
      <CarouselWrapper>
        {icons &&
          icons.map(({ itemDescription, itemIcon }) => (
            <ImageWrap key={itemDescription}>
              <Image
                alt={itemDescription}
                sm={{
                  src: getIconSrc(itemIcon),
                  width: '48px',
                  height: '48px',
                }}
                md={{
                  src: getIconSrc(itemIcon),
                  width: '74px',
                  height: '74px',
                }}
              />
              <ImageDesc>{itemDescription}</ImageDesc>
            </ImageWrap>
          ))}
      </CarouselWrapper>
    </>
  );
};

export default SWAvailableAppsCarouselIcons;
