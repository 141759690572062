import { css } from '@emotion/react';
import { rem } from 'polished';

export const globalStyles = css`
  /* Vodafone Brand Colors */
  :root {
    --red: #e60000;
    --crimsonRed: #900;
    --white: #fff;
    --gallery: #ebebeb;
    --DustyGray: #999;
    --mineShaft: #333;
    --black: #000;
    --MunsellBlue: #0099af;
  }

  @media screen and (max-width: 639px) {
    :root {
      /* breakpoint mobile specific vars */
      --mobileDisplayNone: none;
      --mobileFullWidth: 100%;
      --mobileNoFullWidth: none;
    }
  }

  @media screen and (max-width: 1024px) {
    :root {
      /* breakpoint desktop specific vars */
      --desktopDisplayNone: none;
      --mobileFullWidth: 100%;
    }
  }

  @media screen and (max-width: 1844px) {
    :root {
      /* breakpoint tv specific vars */
      --tvDisplayNone: none;
    }
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100%;
  }

  body {
    #ErrorModal {
      background: #333e;

      div {
        [data-component-name='Container'] {
          background: transparent;
          box-shadow: none;
          header {
            background: transparent;
          }
        }
      }
      svg {
        stroke: var(--white);
      }
    }

    [data-selector='ms-domain-modal'] {
      div > div > div[data-selector='ms-domain-modal-container'] {
        background-color: transparent;
        box-shadow: none;
      }
      header:first-child[data-selector='ms-domain-modal-header'] {
        background-color: transparent;
      }
      button[data-selector='ms-domain-modal-close-button'] {
        svg {
          stroke: var(--white);
          stroke-width: 2;
        }
      }
    }

    background: var(--gallery);

    [class*='FlyoutRendererstyle__FlyoutRendererContent'] {
      width: 100%;

      [class*='FlyoutRendererstyle__FocusLock'] {
        position: relative;
        z-index: 10;
      }

      [class*='BasketSummarystyle__BasketFlyoutWrapper-sc'] {
        width: inherit;
        margin: 0 -1.25rem;

        @media only screen and (min-width: 1025px) {
          margin: 0 -1.4rem;
        }
      }
    }

    [class*='ModalRendererstyle__ModalRenderer-sc'] {
      background: #2a2a2a;
      position: absolute;
      height: ${rem('8000px')};
      z-index: 10;
      [class*='ModalRendererstyle__ModalWrapper-sc'] {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        [class*='ModalRendererstyle__ContainerWrapper-sc'] {
          height: 100%;
          [class*='Interactionstyle__BlankInteraction-sc'] {
            background: none;
            [class*='ModalRendererstyle__ModalContent-sc'] {
              padding: 0;
              overflow: hidden;
              [class*='LoginModalWrapper'] {
                overflow: hidden;
                [class*='SimpleGridstyle__SimpleGrid-sc'] {
                  margin: 0 auto;
                  max-width: ${rem('740px')};

                  @media only screen and (min-width: 768px) {
                    max-width: ${rem('740px')};
                  }

                  @media only screen and (min-width: 1025px) {
                    max-width: ${rem('980px')};
                  }
                }
              }
            }
            [class*='CloseButtonstyle__CloseButton'] {
              svg {
                stroke: var(--white);
              }
            }
          }
        }
      }
    }
    *:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    *:focus-visible {
      outline: none !important;
      box-shadow: var(--MunsellBlue) 0px 0px 0px ${rem('3px')} !important;
    }
  }

  /*
  ** Hide the chat application until displayed by the chat button
  */
  [data-selector='MESSENGER'] {
    display: none;
  }
`;
