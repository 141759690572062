import React, { useState } from 'react';
import { CheckboxList, CheckboxListProps } from '@vfuk/core-checkbox-list';
import { TextAreaInputWithLabel, TextAreaInputWithLabelProps } from '@vfuk/core-text-area-input-with-label';
import { TextInputWithLabel, TextInputWithLabelProps } from '@vfuk/core-text-input-with-label';

import { FormProps, IFormInput, IFormState } from './Form.types';
import { InputContainer } from './Form.styles';

const Form = ({ formInputs }: FormProps) => {
  const initialIFormState = formInputs.reduce((acc, input) => {
    acc[input.name] = { value: '', status: undefined, statusMessage: '' };
    return acc;
  }, {} as IFormState);

  const [form, setForm] = useState<IFormState>(initialIFormState);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    customValue?: string | boolean
  ) => {
    const { name, value } = e.target;
    setForm(prev => ({
      ...prev,
      [name as keyof IFormState]: {
        ...prev[name as keyof IFormState],
        value: customValue != undefined ? customValue : value,
      },
    }));
  };

  const handleStatusChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    status: 'success' | 'error',
    statusMessage = ''
  ) => {
    const { name } = e.target;
    setForm(prev => ({
      ...prev,
      [name as keyof IFormState]: { ...prev[name as keyof IFormState], status, statusMessage },
    }));
  };

  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const isValidPhone = (phone: string) => {
    const regex = /^\+44 \d{2} \d{3} \d{2} \d{2}$/;
    return regex.test(phone);
  };

  const handleValidation = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    validationType: 'length' | 'email' | 'phone' | undefined,
    validationSize = 3
  ) => {
    switch (validationType) {
      case 'length':
        if (e.target.value.length > validationSize) {
          handleStatusChange(e, 'success');
        } else {
          handleStatusChange(e, 'error', `${e.target.name} should have more than ${validationSize} characters.`);
        }
        break;
      case 'email':
        if (isValidEmail(e.target.value)) {
          handleStatusChange(e, 'success');
        } else {
          handleStatusChange(e, 'error', 'Invalid email format.');
        }
        break;
      case 'phone':
        if (isValidPhone(e.target.value)) {
          handleStatusChange(e, 'success');
        } else {
          handleStatusChange(e, 'error', 'Invalid phone number format.');
        }
        break;
    }
  };

  const renderInput = (input: IFormInput) => {
    const commonProps = {
      id: `${input.name}Input`,
      state: form[input.name].status,
      fieldWrapper: {
        label: input.label,
        stateText: form[input.name].statusMessage,
        width: (input.fullWidth && 'full') as TextInputWithLabelProps['fieldWrapper']['width'],
      },
    };

    switch (input.inputType) {
      case 'inputField':
        const textInputProps: TextInputWithLabelProps = {
          ...commonProps,
          textInput: {
            id: `${input.name}Text`,
            name: input.name,
            placeholder: input.placeholder,
            value: form[input.name].value as string,
            onChange: handleInputChange,
            onBlur: e => handleValidation(e, input.validationType, input.lengthValidationSize),
          },
        };

        return (
          <InputContainer fullWidth={input.fullWidth}>
            <TextInputWithLabel {...textInputProps} />
          </InputContainer>
        );
      case 'inputArea':
        const textareaInputProps: TextAreaInputWithLabelProps = {
          ...commonProps,
          textAreaInput: {
            id: `${input.name}Text`,
            rows: 3,
            name: input.name,
            placeholder: input.placeholder,
            value: form[input.name].value as string,
            onChange: handleInputChange,
            onBlur: e => handleValidation(e, input.validationType, input.lengthValidationSize),
          },
        };

        return (
          <InputContainer fullWidth={input.fullWidth}>
            <TextAreaInputWithLabel {...textareaInputProps} />
          </InputContainer>
        );
      case 'checkbox':
        const checkboxProps: CheckboxListProps = {
          fieldWrapper: { label: '', showLabel: false },
          checkboxes: {
            groupName: input.name,
            id: `${input.name}Checkbox`,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, e.currentTarget.checked),
            items: [
              {
                label: input.label || '',
                value: 'agree',
                checked: form[input.name].value as boolean,
              },
            ],
          },
        };
        return (
          <InputContainer fullWidth={input.fullWidth}>
            <CheckboxList {...checkboxProps} />
          </InputContainer>
        );
    }
  };

  return <>{formInputs.map(input => renderInput(input))}</>;
};

export default Form;
