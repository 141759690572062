import { BasketDataProps } from './SWBasketSummary.types';

export const mockBasketData: BasketDataProps = {
  sections: [
    {
      title: 'Section 1 Main Title',
      items: [
        {
          id: '1',
          title: 'Section 1 Item 1 Title',
          price: {
            upfront: {
              gross: '10.00',
            },
            monthly: {
              gross: '11.00',
            },
          },
          isRemovable: true,
          showIconColumn: false,
        },
      ],
    },
    {
      title: 'Section 2 Main Title',
      items: [
        {
          id: '2',
          title: 'Section 2 Item 1 Title',
          price: {
            upfront: {
              gross: '20.00',
            },
            monthly: {
              gross: '21.00',
            },
          },
          isRemovable: true,
          showIconColumn: false,
        },
        {
          id: '3',
          title: 'Section 2 Item 2 Title',
          price: {
            upfront: {
              gross: '30.00',
            },
            monthly: {
              gross: '31.00',
            },
          },
          isRemovable: true,
          showIconColumn: false,
        },
      ],
    },
    {
      title: 'Section 3 Main Title',
      items: [
        {
          id: '4',
          title: 'Section 3 Item 1 Title with no upfront',
          price: {
            monthly: {
              gross: '41.00',
            },
          },
          isRemovable: true,
          showIconColumn: false,
        },
        {
          id: '5',
          title: 'Section 3 Item 2 Title with no monthly',
          price: {
            upfront: {
              gross: '50.00',
            },
          },
          isRemovable: true,
          showIconColumn: false,
        },
        {
          id: '6',
          title: 'Section 3 Item 3 Title with no costs',
          price: {},
          isRemovable: true,
          showIconColumn: false,
        },
      ],
    },
  ],
  totalPrice: {
    upfront: {
      gross: '12.00',
    },
    monthly: {
      gross: '12.01',
    },
  },
};
