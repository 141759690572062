import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import IconAndTextSlot from '@vfuk/core-card-builder/dist/components/slots/IconAndTextSlot';
import LoadingSpinner from '@vfuk/core-loading-spinner';
import { rem } from 'polished';
import { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { SWPricingContentIconWithTitleProps } from '@/components/pageBuilder/Sections/SWPricingContent/SWPricingContent.types';

import { InfoAndCta } from '../../InfoAndCta';
import { CardHeading, CTAWrapper, FCWrapper, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps } from '../../SWPricingCard.types';
import { IncludesWrapper, PriceDetailsWrapper } from './SWIncludesCard styles';
const SWIncludesCard: FC<CardTypeProps> = ({
  item,
  mainItem,
  selectedItem,
  itemStepName,
  shouldChangeTheme,
  mergedProduct,
  includesCards,
}) => {
  const { width } = useWindowSize();
  const { name, detailAndPricing, dxlInformation } = selectedItem;
  return (
    <>
      {dxlInformation ? (
        <CardHeading pb={rem('14px')} pt={{ base: rem('27px'), md: rem('21px') }} px={5}>
          <Stack direction="row" spacing={5}>
            <Box minH={rem('90px')}>
              <Heading
                as="h3"
                color={shouldChangeTheme ? 'white' : 'mineShaft'}
                fontSize={width >= 768 ? rem('28px') : rem('24px')}
                fontWeight="bold"
                lineHeight={{ base: rem('30px'), md: rem('36px') }}
                mb={0}
              >
                {name ? name : ''}
              </Heading>
            </Box>
            <Box minH={rem('90px')}>
              <PriceDetailsWrapper>
                <h6>
                  <small>{detailAndPricing?.currencySymbol}</small>
                  {dxlInformation?.price[0].price || 0}
                </h6>
                <h5>{detailAndPricing?.thirdEntry}</h5>
              </PriceDetailsWrapper>
            </Box>
          </Stack>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}
      <FCWrapper>
        <IncludesWrapper>
          <Text
            color={'black'}
            fontSize={{ base: 'md', md: 'lg' }}
            lineHeight={rem('18px')}
            marginRight={5}
            marginLeft={5}
            mt={{ base: 5, md: 0 }}
          >
            Includes
          </Text>
          {includesCards?.map((card: SWPricingContentIconWithTitleProps) => (
            <IconAndTextSlot
              appearance="primary"
              icon={{ name: `${card.iconName}` }}
              heading={{ text: `${card.name}` }}
              text={''}
            />
          )) || []}
        </IncludesWrapper>
      </FCWrapper>
      <CTAWrapper>
        {item.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWIncludesCard;
