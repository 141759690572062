import { UserAccount } from '@/store';

export enum OPCOOptions {
  SHOWHOME = 'IE',
  IRELAND = 'IE',
  PORTUGAL = 'PT',
}

export type UserServiceHeaderOptions = {
  customerAccountId: UserAccount['customerAccountId'];
  customerId: UserAccount['customerId'];
  companyName?: UserAccount['companyName'];
  barId?: UserAccount['barId'];
  segment?: UserAccount['segment'];
};

export type UserServiceHeaders = {
  'x-customer-account-id'?: UserAccount['customerAccountId'];
  'x-customer-id'?: UserAccount['customerId'];
  'x-bar-id'?: UserAccount['companyName'];
  'x-segment'?: UserAccount['barId'];
  'x-company-name'?: UserAccount['segment'];
};
