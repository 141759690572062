import styled from 'styled-components';
import { rem } from 'polished';
import {Flex} from "@chakra-ui/react";

export const QuickLinksWrapper = styled.div`
  background-image: linear-gradient(#e60000, #820000);
  padding: ${rem('8px')};
`;

export const LinkWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const LinkItem = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${rem('10px')};
  width: rem('120px');
  height: rem('120px');
  color: #fff;

  img {
    border-radius: 100%;
    border: 1px solid #fff;
    width: rem('80px');
    height: rem('80px');
    object-fit: cover;
    margin-bottom: rem('10px');
  }
`;
