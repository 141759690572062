import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';
import { rem } from 'polished';

const DetailTitle = forwardRef<HeadingProps, 'div'>(({ children, ...props }, ref) => (
  <Heading
    ref={ref}
    color="mineShaft"
    {...props}
    as="h5"
    fontSize={rem('14px')}
    letterSpacing={rem('-0.5px')}
    lineHeight={4}
    mb={-1}
    noOfLines={2}
    h={8}
  >
    {children}
  </Heading>
));

export default DetailTitle;
