import { forwardRef, Grid, GridProps } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import RadioButtonList from '@vfuk/core-radio-button-list';
import { startCase } from 'lodash';
import { rem } from 'polished';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { costOnePrice, costTwoPrice } from '@/helpers/findPrices';
import { ProductBundleDetailAndPricing } from '@/types/Product.types';

import DetailInfo from './DetailInfo';
import DetailTitle from './DetailTitle';
import { DetailItem } from './SWPricingCard.styles';
import { DetailsAndPricingProps } from './SWPricingCard.types';

const BaseGrid = forwardRef<GridProps & Pick<DetailsAndPricingProps, 'color'>, 'div'>(({ children, ...props }, ref) => {
  return (
    <Grid
      ref={ref}
      gap={`0 ${rem('16px')}`}
      pb={4}
      pt={3}
      px={{ base: 5 }}
      templateColumns={{
        base: 'repeat(2, 1fr)',
      }}
      mb={0}
      {...props}
    >
      {children}
    </Grid>
  );
});

export const DetailsAndPricing: FC<DetailsAndPricingProps> = ({
  itemStepName,
  item,
  color,
  useSimplePrice,
  selectedEdition,
  setSelectedEdition,
}) => {
  const {
    currencySymbol,
    firstEntry,
    firstEntryValue,
    secondEntry,
    secondEntryValue,
    thirdEntryValueColor,
    fourthEntry,
    useCustomThirdEntryValue,
  } = item?.detailAndPricing as ProductBundleDetailAndPricing;
  const withIncludedBundles = item.bundleIncludes && item.bundleIncludes.length > 0;
  const withExtraInformation = item.extraInformation && item.extraInformation.length > 0;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const checkedItem = useMemo(() => {
    if (item.dxlInformation?.periods && item.dxlInformation?.periods.length > 0 && selectedEdition) {
      return item.dxlInformation?.periods.find(period => period.uuid === selectedEdition);
    } else if (item.dxlInformation?.periods && item.dxlInformation?.periods.length > 0 && !selectedEdition) {
      setSelectedEdition(item.dxlInformation?.periods[0].uuid);
      return item.dxlInformation?.periods[0];
    } else {
      return undefined;
    }
  }, [selectedEdition]);

  const thirdEntry = useMemo(() => {
    if (checkedItem && !useCustomThirdEntryValue) {
      const text = item.detailAndPricing?.thirdEntry?.split(' ').slice(1).join(' ');
      return `${startCase(checkedItem.type.toLowerCase())} ${text}`;
    } else {
      return item.detailAndPricing?.thirdEntry;
    }
  }, [item, checkedItem]);

  const thirdEntryPrice = useMemo(() => {
    if (useCustomThirdEntryValue) return item.detailAndPricing?.thirdEntryValue;
    if (checkedItem) {
      return costOnePrice(checkedItem.prices);
    } else if (useSimplePrice) {
      return item.dxlInformation?.price[0].price;
    } else if (
      (!useSimplePrice && itemStepName === 'Service Extra') ||
      pathname.includes('apphelp-microsoft-365-migration')
    ) {
      return costTwoPrice(item.dxlInformation?.price);
    } else {
      return costOnePrice(item.dxlInformation?.price);
    }
  }, [useSimplePrice, item, checkedItem]);

  const fourthEntryPrice = costTwoPrice(item.dxlInformation?.price);

  return (
    <>
      <BaseGrid color={color} mb={withIncludedBundles || withExtraInformation ? `${rem('16px')}` : 0}>
        <DetailItem>
          <DetailTitle color={color}>{firstEntry && firstEntry}</DetailTitle>
          <DetailInfo color={color}>{firstEntryValue && firstEntryValue}</DetailInfo>
        </DetailItem>

        <DetailItem>
          <DetailTitle color={color}>{secondEntry && secondEntry}</DetailTitle>
          <DetailInfo color={color}>{secondEntryValue && secondEntryValue}</DetailInfo>
        </DetailItem>

        <DetailItem>
          <DetailTitle color={color}>{thirdEntry}</DetailTitle>
          {thirdEntryPrice && (
            <DetailInfo {...(thirdEntryValueColor && { color: color ? color : thirdEntryValueColor })}>
              <small>{currencySymbol}</small>
              {thirdEntryPrice}
            </DetailInfo>
          )}
          <DetailTitle color={color}>{t('priceSuffix')}</DetailTitle>
        </DetailItem>

        {fourthEntry && (
          <DetailItem>
            <DetailTitle color={color}>{fourthEntry}</DetailTitle>
            {fourthEntryPrice && (
              <DetailInfo color={color}>
                <small>{currencySymbol}</small>
                {fourthEntryPrice}
              </DetailInfo>
            )}
            <DetailTitle color={color}>{t('priceSuffix')}</DetailTitle>
          </DetailItem>
        )}
      </BaseGrid>

      {(!!selectedEdition || checkedItem) &&
        item.dxlInformation?.periods &&
        item.dxlInformation?.periods.length > 0 && (
          <BaseGrid color={color}>
            <RadioButtonList
              fieldWrapper={{
                label: 'Preselected Radio',
                showLabel: false,
              }}
              radioButtons={{
                groupName: 'Preselected',
                id: item.dxlInformation.uuid,
                checked: selectedEdition,
                onChange: event => {
                  setSelectedEdition(event.target.value);
                },
                items: item.dxlInformation.periods
                  .filter(period => item.editionsPeriodsByUuid?.includes(period.uuid))
                  .map(period => ({
                    label: startCase(`${period.type} contract`.toLowerCase()),
                    value: period.uuid,
                  })),
              }}
            />
          </BaseGrid>
        )}
    </>
  );
};
